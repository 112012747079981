<template>
    <preset-list-item
        :preset="preset"
        :headers="headers"
        :items="items"
        @onEditPreset="onEditPreset"
        @onDeletePreset="onDeletePreset"
        @onRunQuickReport="onRunQuickReport"
    />
</template>

<script>
import {
    parsePresetCreatedBy,
    parsePresetLocations,
    parsePresetEmploymentType,
    parsePresetStaffGroups,
    parsePresetJobTitles,
    parsePresetStaffMembers,
} from "../../../../lib/helpers/reports";
import PresetListItem from "../PresetListItem";

export default {
    name: "PayrollPeriodPresetListItem",
    components: {PresetListItem},
    props: {
        preset: {
            type: Object,
            default: () => ({}),
        }
    },
    computed: {
        headers() {
            const headers = [
                {text: 'Created By', value: 'createdBy', width: this.maxWidths.createdBy},
                {text: 'Employment Type', value: 'employmentType', width: this.maxWidths.employmentType},
                {text: 'Staff Groups', value: 'staffGroups', width: this.maxWidths.staffGroups},
                {text: 'Job Titles', value: 'jobTitles', width: this.maxWidths.jobTitles},
                {text: 'Staff Members', value: 'staffMembers', width: this.maxWidths.staffMembers},
                {text: 'Last Updated', value: 'lastUpdated', width: this.maxWidths.lastUpdated},
            ]
            if (!this.globalLocation) {
                headers.splice(1, 0,
                    {text: 'Location', value: 'location', width: this.maxWidths.location})
            }
            return headers
        },
        items() {
            return [{
                createdBy: this.createdBy,
                ...(!this.globalLocation && {location: this.location}),
                employmentType: this.employmentType,
                staffGroups: this.staffGroups,
                jobTitles: this.jobTitles,
                staffMembers: this.staffMembers,
                lastUpdated: this.lastUpdated,
            }]
        },
        createdBy() {
            return parsePresetCreatedBy(this.preset)
        },
        employmentType() {
            return parsePresetEmploymentType(this.preset)
        },
        staffGroups() {
            return parsePresetStaffGroups(this.preset)
        },
        jobTitles() {
            return parsePresetJobTitles(this.preset)
        },
        staffMembers() {
            return parsePresetStaffMembers(this.preset)
        },
        location() {
            return parsePresetLocations(this.preset)
        },
        lastUpdated() {
            return moment(this.preset.updated_at).tz(this.globalTimezone).format('MMM D, YYYY')
        },
        globalLocation() {
            return this.$root.globalLocation
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
        maxWidths() {
            return {
                createdBy: '15%',
                employmentType: this.globalLocation ? '17.5%' : '14.5%',
                staffGroups: this.globalLocation ? '17.5%' : '14.5%',
                jobTitles: this.globalLocation ? '17.5%' : '14.5%',
                staffMembers: this.globalLocation ? '17.5%' : '14.5%',
                location: '12%',
                lastUpdated: '15%',
            }
        }
    },
    methods: {
        onEditPreset(preset) {
            this.$emit('onEditPreset', preset)
        },
        onDeletePreset(preset) {
            this.$emit('onDeletePreset', preset)
        },
        onRunQuickReport(preset) {
            this.$emit('onRunQuickReport', preset)
        }
    }
}
</script>

<style scoped>

</style>
