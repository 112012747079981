<template>
    <v-row>
        <v-col class="d-flex justify-center align-center">
            <yellow-clock-icon class="mr-5"></yellow-clock-icon>
            <event-status
                text="Shift Time Edited"
                inner-color="rgba(242, 194, 102, 0.4)"
                outer-color="rgb(242, 194, 102)"></event-status>
        </v-col>
    </v-row>
</template>

<script>
import EventStatus from "./EventStatus";
import YellowClockIcon from "../CustomIcon/YellowClockIcon";

export default {
    name: "EventStatusTimeEdited",
    components: { EventStatus, YellowClockIcon }
}
</script>

<style scoped>

</style>
