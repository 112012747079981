<template>
    <time-picker-dialog
        v-model="time"
        :text-field-props="textFieldProps"
        required
        @onClearTime="onClearTime"
        @onConfirmTime="onConfirmTime"
    ></time-picker-dialog>
</template>

<script>
import TimePickerDialog from "../../../modals/TimePickerDialog";
export default {
    name: "EndTimePicker",
    components: { TimePickerDialog },
    props: {
        value: {
            type: String,
            default: null,
        }
    },
    computed: {
        time: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        textFieldProps() {
            return {
                label: 'End Time',
                class: 'required',
                value: this.formattedTime,
            }
        },
        formattedTime() {
            const { time } = this
            if(time) {
                const now = moment().format('YYYY-MM-DD')
                return moment.tz(`${now} ${time}`, this.globalTimezone).format('h:mm A')
            }
            return null
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
    },
    methods: {
        onClearTime() {
            this.$emit('onClearTime')
        },
        onConfirmTime() {
            this.$emit('onConfirmTime')
        }
    }
}
</script>

<style scoped>

</style>
