<template>
    <v-row>
        <v-col class="d-flex justify-center align-center">
            <grey-close-icon class="mr-5"></grey-close-icon>
            <event-status
                text="cancellation denied"
                data-cy="event-status"
                inner-color="rgb(206, 206, 206)"
                outer-color="rgb(107, 107, 107)"></event-status>
        </v-col>
    </v-row>
</template>

<script>
import GreyCloseIcon from "../CustomIcon/GreyCloseIcon";
import EventStatus from "./EventStatus";
export default {
    name: "EventStatusCancellationDenied",
    components: { GreyCloseIcon, EventStatus }
}
</script>

<style scoped>

</style>
