<template>
    <v-card>
        <v-card-actions class="justify-space-between align-center" style="height: 80px; background-color: #DFE0FF;">
            <span class="text--h2" style="color: #000D60;">Shifts</span>
            <span id="timeline-date-label" class="text--h4" style="color: #000D60;">{{ timeline.title }}</span>
        </v-card-actions>
        <v-card-text class="pb-1">
            <v-timeline
                v-if="timeline.events.length > 0"
                dense
                align-top
                small
                class="pt-2"
                dusk="eventsTimeline"
            >
                <v-timeline-item
                    v-for="(event, i) in parsedEvents"
                    :key="i"
                    :color="event.color"
                    class="pb-5"
                    dusk="timelineEvent"
                    :data-date="event.metadata_date"
                    :data-time="event.metadata_start_time"
                    :data-type="event.type"
                >
                    <v-card
                        :color="event.color"
                        @click="onClickEvent(event.id)"
                    >
                        <v-card-title class="text--label pb-4">{{ event.start }}</v-card-title>
                        <v-card-text class="pt-3 text--caption" style="background-color: white !important;">
                            <div>
                                <div class="mb-2">
                                    <v-icon color="primary_text">mdi-city</v-icon> {{ event.location.name }}
                                </div>
                                <div class="mb-2">
                                    <v-icon color="primary_text">mdi-domain</v-icon> {{ event.department.name }}
                                </div>
                                <div class="mb-2">
                                    <v-icon color="primary_text">mdi-account-group</v-icon> {{ event.staff_group.name }}
                                </div>
                                <div>
                                    <v-icon color="primary_text">mdi-clipboard-account</v-icon> {{ event.job_title }}
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-timeline-item>
            </v-timeline>
            <div dusk="timelineEmptyMessage" v-else class="body-1 text-center py-2">You have no shifts during this time period. Update your Availability and accept Shift Offers to start building your work schedule.</div>
        </v-card-text>
        <v-card-actions>
            <v-pagination
                v-if="Math.ceil(timeline.events.length / pagination.paginationFactor) > 1"
                v-model="pagination.page"
                :length="Math.ceil(timeline.events.length / pagination.paginationFactor)"
                :total-visible="pagination.totalVisible"
                circle
            ></v-pagination>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "Timeline",
        props: {
            timeline: {
                type: Object,
                default: function() {
                    return {
                        title: '',
                        events: []
                    }
                }
            }
        },
        data: function() {
            return {
                pagination: {
                    page: 1,
                    paginationFactor: 3,
                    totalVisible: 4
                },
                colors: {
                    'accepted' : '#6BC96E',
                    'requested' : '#F2C266',
                    'pending cancellation': '#6BC96E',
                    'open shift': '#C8E6FF',
                    'filled': '#6BC96E'
                }
            }
        },
        computed: {
            parsedEvents: function() {
                const { page, paginationFactor } = this.pagination
                const startIndex = (page-1) * paginationFactor
                const endIndex = page * paginationFactor
                return this.timeline.events
                    .filter(event => event.status !== 'rejected')
                    .map(event => ({
                        ...event,
                        date: this.getDate(event),
                        color: this.getColor(event),
                        start: this.parseDate(event.start),
                        end: this.parseDate(event.end),
                        metadata_date: this.parseMetadataDate(event.start),
                        metadata_start_time: this.parseMetadataTime(event.start),
                        id: this.getId(event)
                    }))
                    .slice(startIndex, endIndex)
            },

            globalTimezone() {
                return this.$root.globalTimezone
            }
        },
        methods: {
            parseHour: function(date) {
                return moment(date).tz(this.globalTimezone).format('LT')
            },
            parseDate: function(date) {
                return moment(date).tz(this.globalTimezone).format('MM/DD/YYYY, LT')
            },
            parseMetadataDate: function(date) {
                return moment(date).tz(this.globalTimezone).format('YYYY-MM-DD')
            },
            parseMetadataTime: function(date) {
                return moment(date).tz(this.globalTimezone).format('HH:mm')
            },
            getDate: function(event) {
                return `${this.parseHour(event.start)} - ${this.parseHour(event.end)}`
            },
            getColor: function(event) {
                return this.colors[event.status]
            },
            getId: function(event) {
                const { shift_id, shift_request_id, shift_offer_id } = event
                return event.type === 'shift' ? shift_id : event.type === 'open_shift' ? shift_request_id : shift_offer_id;
            },
            onClickEvent: function(eventId) {
                this.$emit('onClickEvent', eventId)
            },
            resetPaginationPage() {
                this.pagination.page = 1
            }
        }
    }
</script>

<style scoped>

</style>
