import { render, staticRenderFns } from "./JobTitlesLoadingSkeleton.vue?vue&type=template&id=1f36af70&scoped=true&"
import script from "./JobTitlesLoadingSkeleton.vue?vue&type=script&lang=js&"
export * from "./JobTitlesLoadingSkeleton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f36af70",
  null
  
)

export default component.exports