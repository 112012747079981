<template>
    <v-btn
        class="circle-container"
        :class="{'circle-container-selected': selected}"
        :disabled="disabled"
        elevation="0"
        small
        fab
        @click="onClick"
    >
        {{ initial }}
    </v-btn>
</template>

<script>
export default {
    name: "TimeCircle",
    props: {
        initial: {
            type: String,
            default: '',
        },
        selected: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        onClick () {
            this.$emit('onClick')
        }
    }
}
</script>

<style scoped>
    .circle-container {
        background-color: #F4F6F7 !important;
        border-color: #F4F6F7;
        text-transform: none !important;
    }

    .circle-container-selected {
        background-color: #4152C5 !important;
        border-color: #4152C5;
        color: #FFFFFF;
    }
</style>
