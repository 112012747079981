<template>
    <custom-icon icon="mdi-clock-outline" color="rgb(242, 182, 66)"></custom-icon>
</template>

<script>
import CustomIcon from "./CustomIcon";
export default {
    name: "YellowClockIcon",
    components: { CustomIcon }
}
</script>

<style scoped>

</style>
