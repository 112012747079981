<template>
    <v-row>
        <v-col cols="12" md="3">
            <Timeline
                ref="timeline"
                :timeline="timeline"
                @onClickEvent="onClickTimelineEvent"
            >
            </Timeline>
        </v-col>
        <v-col cols="12" md="9">
            <Calendar
                :events="events"
                :calendar-type="calendar.type"
                :calendar-title="calendar.title"
                :selected-event="selectedEvent"
                :loading="api.busy"
                @onCalendarPrev="onCalendarPrev"
                @onCalendarNext="onCalendarNext"
                @onCalendarWeekly="onCalendarWeekly"
                @onCalendarMonthly="onCalendarMonthly"
                @onEventTypeChange="onEventTypeChange"
                @onOpenShiftClaim="onOpenShiftClaim"
                @onShiftOfferAccepted="onShiftOfferAccepted"
                @onShiftOfferAcceptFailed="onShiftOfferAcceptFailed"
                @onShiftOfferRejected="onShiftOfferRejected"
                @onRequestShiftCancellation="onRequestShiftCancellation"
                @onCloseModal="onCloseModal"
            >
            </Calendar>
        </v-col>
        <v-dialog v-model="showModal" scrollable max-width="400px" persistent>
            <v-card>
                <v-card-title class="headline">
                    Event information
                </v-card-title>

                <v-card-text>
                    <p>
                        In order to see the event on the calendar, you must have a
                        <strong>shift assigned</strong> or <strong>shift offer sent</strong> to you
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-row no-gutters>
                        <v-col>
                            <v-btn text color="primary" @click="onValidModalClose">Okay</v-btn>
                        </v-col>
                    </v-row>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import Calendar from "./Calendar";
    import Timeline from './Timeline'

    export default {
        name: "index",
        components: { Calendar, Timeline },
        data: function() {
            const events = this.$appData.events
            const selectedEvent = this.$appData.selectedEvent
            let isValid = typeof this.$appData.isValid === 'undefined' ? true : this.$appData.isValid
            let findEvent = null
            if (selectedEvent) {
                findEvent = events.find(event => event.shift_id === selectedEvent || event.shift_offer_id === selectedEvent || event.shift_request_id === selectedEvent)
            }
            const fromTime = findEvent ? moment(findEvent.start).tz(this.$root.globalTimezone) : moment().tz(this.$root.globalTimezone)
            const moments = {
                weekStartDate: moment(fromTime.clone().startOf('week')),
                weekEndDate: moment(fromTime.clone().endOf('week')),
                monthDate: moment(fromTime)
            }
            return {
                events,
                selectedEvent,
                isValid,
                moments,
                shifts: this.$appData.shifts,
                departments: this.$appData.departments,
                staffGroups: this.$appData.staffGroups,
                shiftTimes: this.$appData.shiftTimes,
                calendar: {
                    type: 'month',
                    title: ''
                },
                showModal: !isValid,
                api: new formHelper(),
                searchParams: {
                    start: moments.monthDate.clone().startOf('month').subtract(6, 'd').format('YYYY-MM-DD HH:mm'),
                    end: moments.weekEndDate.clone().endOf('month').add(6, 'd').format('YYYY-MM-DD HH:mm'),
                    event_type: 'all'
                }
            }
        },
        computed: {
            timeline: function() {
                if(this.isCalendarWeekly()){
                    return this.getWeeklyTimeline()
                }
                return this.getMonthlyTimeline()
            },
            globalLocation: function(){
                return this.$root.globalLocation
            },
        },
        methods: {
            loadEvents: _.debounce(function () {
                this.searchParams.location_id = this.globalLocation ? this.globalLocation._id : null
                this.api.post('/shifts-for-date-range/' + this.$user._id, this.searchParams)
                    .then(({data, message}) => {
                        this.events = data
                        this.$refs.timeline.resetPaginationPage()
                    })
            }, 400),

            onCalendarPrev: function({start, end, type}) {
                this.onCalendarDateChange(start, end, type)
            },
            onCalendarNext: function({start, end, type}) {
                this.onCalendarDateChange(start, end, type)
            },
            onCalendarWeekly: function({start, end}) {
                this.onCalendarDateChange(start, end, 'week')
            },
            onCalendarMonthly: function({start, end}) {
                this.onCalendarDateChange(start, end, 'month')
            },
            onEventTypeChange: function({start, end, type,  event_type}) {
                this.searchParams.event_type = event_type
                this.onCalendarDateChange(start, end, type)
            },
            onCalendarDateChange: function(start, end, type) {
                this.setWeekStartDate(start.date)
                this.setWeekEndDate(end.date)
                this.setMonthDate(start.date)
                this.setCalendarType(type)
                this.setCalendarTitle()

                this.events = []

                let start_date = this.moments.weekStartDate
                let end_date = this.moments.weekEndDate

                if (type === 'month') {
                    start_date = start_date.clone().subtract(6, 'd')
                    end_date = end_date.clone().add(6, 'd')
                }

                this.searchParams.start = start_date.startOf('day').format('YYYY-MM-DD HH:mm')
                this.searchParams.end = end_date.endOf('day').format('YYYY-MM-DD HH:mm')

                this.loadEvents()
            },
            onClickTimelineEvent: function(eventId) {
                this.selectedEvent = eventId
            },
            onCloseModal: function() {
                this.selectedEvent = null
            },
            isBetween: function(date, start, end) {
                return(date.isSame(start, 'week') && date.isSame(end, 'week'))
            },
            isSameMonth: function(start, end) {
                return start.isSame(end, 'month')
            },
            getWeeklyTimeline: function() {
                const { weekStartDate, weekEndDate } = this.moments
                return {
                    title: this.getWeeklyCalendarTitle(),
                    events: this.events.filter(event => this.isBetween(moment(event.start), weekStartDate, weekEndDate))
                }
            },
            getMonthlyTimeline: function() {
                const { monthDate } = this.moments
                return {
                    title: this.getMonthlyCalendarTitle(),
                    events: this.events.filter(event => this.isSameMonth(moment(event.start), monthDate))
                }
            },
            getWeeklyCalendarTitle: function() {
                const { weekStartDate, weekEndDate } = this.moments
                const parseWeekDate = date => date.format('MMM Do')
                return `${parseWeekDate(weekStartDate)} - ${parseWeekDate(weekEndDate)}`
            },
            getMonthlyCalendarTitle: function() {
                return this.moments.monthDate.format('MMMM YYYY')
            },
            setWeeklyCalendarTitle: function() {
                this.calendar.title = this.getWeeklyCalendarTitle()
            },
            setMonthlyCalendarTitle: function() {
                this.calendar.title = this.getMonthlyCalendarTitle()
            },
            setCalendarTitle: function() {
                if(this.isCalendarWeekly()) {
                    this.setWeeklyCalendarTitle()
                } else {
                    this.setMonthlyCalendarTitle()
                }
            },
            setCalendarType: function(type) {
                this.calendar.type = type
            },
            setWeekStartDate: function(weekStartDate) {
                return this.setMoment('weekStartDate', weekStartDate)
            },
            setWeekEndDate: function(weekEndDate) {
                return this.setMoment('weekEndDate', weekEndDate)
            },
            setMonthDate: function(monthDate) {
                return this.setMoment('monthDate', monthDate)
            },
            setMoment: function(key, date) {
                this.moments = {
                    ...this.moments,
                    [key]: moment(date, 'YYYY-MM-DD')
                }
                return this.moments[key]
            },
            isCalendarWeekly: function() {
                return this.calendar.type === 'week'
            },
            onOpenShiftClaim (shift_request_id) {
                this.onEventStatusChanged(shift_request_id, 'filled')
                this.filterOverlappingOpenShifts(shift_request_id)
            },
            filterOverlappingOpenShifts(shift_request_id) {
                const actual_event = this.events.find(event => event.shift_request_id === shift_request_id)

                if (actual_event === undefined) {
                    return
                }

                const actual_event_date_range = {
                    start: moment(actual_event.start),
                    end: moment(actual_event.end)
                }

                this.events = this.events.filter(event => {
                    if (event.shift_request_id === shift_request_id) {
                        return true
                    }

                    if (event.type !== 'open_shift') {
                        return true
                    }

                    let current_event_date_range = {
                        start: moment(event.start),
                        end: moment(event.end)
                    }

                    return !this.dateRangesIntersect(actual_event_date_range, current_event_date_range)
                })
            },
            dateRangesIntersect(date_range_1, date_range_2) {
                return date_range_1.start <= date_range_2.end && date_range_1.end >= date_range_2.start
            },
            onShiftOfferAccepted (id, shiftId) {
                this.onEventStatusChanged(id, 'accepted', shiftId)
            },
            onShiftOfferAcceptFailed ({event_id, should_remove_offer}) {
                if (!should_remove_offer) {
                    return
                }

                this.events = this.events.filter(event => {
                    const { shift_offer_id } = event
                    return shift_offer_id !== event_id;
                })
            },
            onShiftOfferRejected: function(id) {
                this.onEventStatusChanged(id, 'rejected')
            },
            onRequestShiftCancellation: function(id) {
                this.onEventStatusChanged(id, 'pending cancellation')
            },
            onEventStatusChanged: function(id, status, shiftId) {
                this.events = this.events
                    .map(event => {
                        const { shift_offer_id, shift_id, shift_request_id } = event
                        if(shift_offer_id === id || shift_id === id || shift_request_id === id) {
                            return {
                                ...event,
                                status,
                                ...shiftId && { shift_id: shiftId }
                            }
                        }
                        return event
                    })
            },
            onValidModalClose: function() {
                this.showModal = false
            }
        },
        created() {
            this.setCalendarTitle()
        },
        watch: {
            globalLocation() {
                this.loadEvents()
            }
        }
    }
</script>

<style scoped>

</style>
