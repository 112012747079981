import moment from 'moment'

const NOTIFICATION_TYPES = {
    ASSIGN_SHIFT: 'assign_shift',
    OFFER_SHIFT: 'shift_offer',
    DECLINE_SHIFT: 'shift_offer_declined',
    ACCEPT_SHIFT: 'shift_offer_accepted',
    CANCEL_SHIFT_ACCEPTED: 'unassigned_shift',
    CANCEL_SHIFT_DECLINED: 'shift_cancellation_request_declined',
    CANCEL_SHIFT_REQUESTED: 'shift_cancellation_request',
    CANCEL_SHIFT_APPROVED: 'shift_cancellation_request_approved',
    SHIFT_FILLED: 'shift_filled',
    USER_UNASSIGNED: 'user_unassigned',
    SHIFT_UPDATED: 'updated_shift',
    SHIFT_OFFER_UPDATED: 'shift_offer_updated',
    AVAILABILITY_REMINDER: 'availability_reminder',
    OWN_STAFFING_AUTOMATION_RUN_COMPLETED: 'own_staffing_automation_run_completed',
    MANAGED_STAFFING_AUTOMATION_RUN_COMPLETED: 'managed_staffing_automation_run_completed',
}

function parseEventDate(date) {
    const m = moment(date)
    return {
        dayOfWeek: m.format('dddd'),
        month: m.format('MMMM'),
        dayOfMonth: m.format('Do'),
        hour: m.format('LT')
    }
}

function parseAt(event) {
    if (!event) {
        return false
    }
    const times = parseEventDate(event.start)
    return `at ${event.location_name} in ${event.department_name} at ${times.dayOfWeek}, ${times.month} ${times.dayOfMonth} at ${times.hour}`
}

function parseLocationTimeFromEmbedData(data, timezone) {
    let date = moment(data.start).tz(timezone).format('ddd, MMMM Do [at] LT')
    return `${data.location} in ${data.department} at ${date}`
}

function getAgo(date) {
    const now = moment()
    const start = moment(date)
    if(now.diff(start, 'hours') < 1) {
        const minutes = now.diff(start, 'minutes')
        return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} ago`
    }else if(now.diff(start, 'days') < 1) {
        const hours = now.diff(start, 'hours')
        return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`
    }else if(now.diff(start, 'weeks') < 1) {
        const days = now.diff(start, 'days')
        return `${days} ${days > 1 ? 'days' : 'day'} ago`
    } else {
        return start.format('MMM D, YYYY')
    }
}

function notification_missing_data(title, body, notification_id = null) {
    if (notification_id) {
        console.log('Notification ID: ' + notification_id)
    }

    return {
        title: title,
        body: body,
        ago: ''
    }
}

function getNotificationDetails(notification) {
    const type = notification.data.type
    const ago = getAgo(notification.created_at)
    if(type === NOTIFICATION_TYPES.ASSIGN_SHIFT) {
        const { shift } = notification

        let time = parseAt(shift)
        if (!time) {
            return notification_missing_data('Shift Assigned', 'Error: Shift data is missing, notification might be related to a deleted shift.', notification._id)
        }

        return {
            title: 'Shift Assigned',
            body: `You have been assigned a shift ${parseAt(shift)}`,
            ago,
            disabled: shift.status === 'open' ? true : shift.employee_id !== notification.user_id,
            url: `/shifts/${shift._id}`
        }
    } else if(type === NOTIFICATION_TYPES.SHIFT_UPDATED) {
        const { shift } = notification

        let time = parseAt(shift)
        if (!time) {
            return notification_missing_data('Shift Updated', 'Error: Shift data is missing, notification might be related to a deleted shift.', notification._id)
        }

        return {
            title: 'Shift Updated',
            body: `Your shift ${parseAt(shift)} has been updated`,
            ago,
            disabled: shift.status === 'open' ? true : shift.employee_id !== notification.user_id,
            url: `/shifts/${shift._id}`
        }
    } else if(type === NOTIFICATION_TYPES.OFFER_SHIFT) {
        const { shift_offer } = notification

        let time = parseAt(shift_offer.shift_request)
        if (!time) {
            return notification_missing_data('Available Shift', 'Error: Shift data is missing, notification might be related to a deleted shift.', notification._id)
        }

        let disabled
        if(shift_offer.user_id !== notification.user_id) {
            disabled = true
        } else if(shift_offer.status === 'rejected') {
            disabled = true
        } else if(shift_offer.status === 'accepted') {
            disabled = !shift_offer.assigned_shift_id
        } else {
            disabled = false
        }
        return {
            title: 'Available Shift',
            body: `A shift is available ${parseAt(shift_offer.shift_request)}`,
            ago,
            disabled,
            url: `/shifts/${shift_offer._id}`
        }
    } else if (type === NOTIFICATION_TYPES.SHIFT_OFFER_UPDATED) {
        const { shift_offer } = notification

        let time = parseAt(shift_offer.shift_request)
        if (!time) {
            return notification_missing_data('Shift Offer Updated', 'Error: Shift data is missing, notification might be related to a deleted shift.', notification._id)
        }

        let disabled
        if(shift_offer.user_id !== notification.user_id) {
            disabled = true
        } else if(shift_offer.status === 'rejected') {
            disabled = true
        } else if(shift_offer.status === 'accepted') {
            disabled = !shift_offer.assigned_shift_id
        } else {
            disabled = false
        }
        return {
            title: 'Shift Offer Updated',
            body: `A shift is available ${parseAt(shift_offer.shift_request)}`,
            ago,
            disabled,
            url: `/shifts/${shift_offer._id}`
        }
    } else if(type === NOTIFICATION_TYPES.ACCEPT_SHIFT) {
        let time = parseAt(notification.shift_offer.shift_request)
        if (!time) {
            return notification_missing_data('Shift Accepted', 'Error: Shift data is missing, notification might be related to a deleted shift.', notification._id)
        }

        let url = '/schedules'
        if(notification && notification.shift_offer && notification.shift_offer.user_id &&
            notification.shift && notification.shift.employee_id &&
            notification.shift_offer.user_id === notification.shift.employee_id) {
            url = `/schedules/?type=shift&shift_id=${notification.shift._id}`
        }
        let fullname
        if(notification.shift_offer.user && notification.shift_offer.user.full_name) {
            fullname = notification.shift_offer.user.full_name
        } else {
            const split = notification.data.body.split(' ')
            fullname = `${split[0]} ${split[1]}`
        }
        return {
            title: 'Shift Accepted',
            body: `${fullname} accepted a ${notification.shift_offer.shift_request.job_title_name} shift ${parseAt(notification.shift_offer.shift_request)}`,
            ago,
            disabled: false,
            url
        }
    } else if(type === NOTIFICATION_TYPES.DECLINE_SHIFT) {
        let time = parseAt(notification.shift_offer.shift_request)
        if (!time) {
            return notification_missing_data('Shift Declined', 'Error: Shift data is missing, notification might be related to a deleted shift.', notification._id)
        }
        let fullname
        if(notification.shift_offer.user && notification.shift_offer.user.full_name) {
            fullname = notification.shift_offer.user.full_name
        } else {
            const split = notification.data.body.split(' ')
            fullname = `${split[0]} ${split[1]}`
        }
        return {
            title: 'Shift Declined',
            body: `${fullname} has declined a ${notification.shift_offer.shift_request.job_title_name} shift ${parseAt(notification.shift_offer.shift_request)}`,
            ago,
            disabled: false,
            url: '/schedules'
        }
    } else if(type === NOTIFICATION_TYPES.CANCEL_SHIFT_ACCEPTED) {
        let time = parseAt(notification.shift)
        if (!time) {
            return notification_missing_data('Shift Cancelled', 'Error: Shift data is missing, notification might be related to a deleted shift.', notification._id)
        }

        return {
            title: 'Shift Cancelled',
            body: `Your shift ${parseAt(notification.shift)} has been cancelled`,
            ago,
            disabled: true,
            url: '/shifts'
        }
    } else if(type === NOTIFICATION_TYPES.CANCEL_SHIFT_DECLINED) {
        const { shift } = notification
        let time = parseAt(shift)
        if (!time) {
            return notification_missing_data('Shift Cancellation Request Declined', 'Error: Shift data is missing, notification might be related to a deleted shift.', notification._id)
        }

        return {
            title: 'Shift Cancellation Request Declined',
            body: `Your request to cancel your shift ${parseAt(shift)} has been declined`,
            ago,
            disabled: shift.status === 'open' ? true : shift.employee_id !== notification.user_id,
            url: `/shifts/${shift._id}`
        }
    } else if(type === NOTIFICATION_TYPES.CANCEL_SHIFT_REQUESTED) {
        let time = parseAt(notification.shift)
        if (!time) {
            return notification_missing_data('Shift Cancellation Request', 'Error: Shift data is missing, notification might be related to a deleted shift.', notification._id)
        }

        const parts = notification.data.body.split(' ')
        const firstName = parts[0]
        const lastName = parts[1]
        let url = '/schedules'
        if(notification.shift && notification.shift.employee &&
            notification.shift.employee.first_name &&
            notification.shift.employee.last_name &&
            notification.shift.employee.first_name === firstName &&
            notification.shift.employee.last_name === lastName) {
            url = `/schedules/?type=shift&shift_id=${notification.shift._id}`
        }
        return {
            title: 'Shift Cancellation Request',
            body: `${firstName} ${lastName} has requested a shift cancellation ${parseAt(notification.shift)}. Please find another employee for this shift.`,
            ago,
            disabled: false,
            url
        }
    } else if(type === NOTIFICATION_TYPES.CANCEL_SHIFT_APPROVED) {
        let time = parseAt(notification.shift)
        if (!time) {
            return notification_missing_data('Shift Cancellation Request Approved', 'Error: Shift data is missing, notification might be related to a deleted shift.', notification._id)
        }

        const parts = notification.data.body.split(' ')
        const firstName = parts[0]
        const lastName = parts[1]
        let url = '/schedules'
        if(notification.shift && notification.shift.employee &&
            notification.shift.employee.first_name &&
            notification.shift.employee.last_name &&
            notification.shift.employee.first_name === firstName &&
            notification.shift.employee.last_name === lastName) {
            url = `/schedules/?type=shift&shift_id=${notification.shift._id}`
        }
        return {
            title: 'Shift Cancellation Request Approved',
            body: notification.data.body,
            ago,
            disabled: false,
            url
        }
    } else if (type === NOTIFICATION_TYPES.SHIFT_FILLED) {
        const { shift } = notification

        let time = parseAt(shift)
        if (!time) {
            return notification_missing_data('Shift Assigned', 'Error: Shift data is missing, notification might be related to a deleted shift.', notification._id)
        }

        return {
            title: 'Shift Filled',
            body: notification.data.body,
            ago,
            disabled: shift.status === 'open',
            url: `/schedules/?type=shift&shift_id=${shift._id}`
        }
    } else if (type === NOTIFICATION_TYPES.USER_UNASSIGNED) {
        const { shift, embed_data } = notification

        let time = parseAt(shift)
        if (!time) {
            return notification_missing_data('Shift Canceled', 'Error: Shift data is missing, notification might be related to a deleted shift.', notification._id)
        }

        return {
            title: 'Shift Canceled',
            body: `${embed_data.employee} removed from ${parseAt(shift)}.`,
            ago,
            disabled: shift.status === 'open',
            url: `/schedules/?type=shift_request&shift_id=${shift.shift_request_id}`
        }
    } else if (type === NOTIFICATION_TYPES.AVAILABILITY_REMINDER) {
        return {
            title: notification.title,
            body: notification.data.body,
            ago,
            disabled: false,
            url: notification.data.url
        }
    } else if (type === NOTIFICATION_TYPES.OWN_STAFFING_AUTOMATION_RUN_COMPLETED || type === NOTIFICATION_TYPES.OWN_STAFFING_AUTOMATION_RUN_COMPLETED) {
        return {
            title: notification.title,
            body: notification.data.body,
            ago,
            disabled: false,
            url: notification.url
        }
    } else {
        return {
            title: 'Default title',
            body: 'Default body',
            ago: 'Default ago'
        }
    }
}

function getNotificationBodyFromEmbedData(notification, timezone) {
    if (typeof notification.data.embed_data === 'undefined' || !notification.data.embed_data) {
        return notification.data.body
    }

    const type = notification.data.type
    const data = notification.data.embed_data
    const { employee, job_title } = data
    const location_time = parseLocationTimeFromEmbedData(data, timezone)
    let body = notification.data.body

    switch (type) {
        case NOTIFICATION_TYPES.ASSIGN_SHIFT:
            body = `You have been assigned a shift at: ${location_time}.`
            break
        case NOTIFICATION_TYPES.OFFER_SHIFT:
            body = `A shift is available at ${location_time}.`
            break
        case NOTIFICATION_TYPES.ACCEPT_SHIFT:
            body = `${employee} accepted a ${job_title} shift at ${location_time}.`
            break
        case NOTIFICATION_TYPES.DECLINE_SHIFT:
            body = `${employee} has declined a ${job_title} shift at ${location_time}.`
            break
        case NOTIFICATION_TYPES.CANCEL_SHIFT_ACCEPTED:
            body = `Your shift at ${location_time} has been cancelled.`
            break
        case NOTIFICATION_TYPES.CANCEL_SHIFT_DECLINED:
            body = `Your request to cancel your shift at ${location_time} has been declined.`
            break
        case NOTIFICATION_TYPES.CANCEL_SHIFT_REQUESTED:
            body = `${employee} has requested a shift cancellation at ${location_time}. Please find another employee for this shift.`
            break
        case NOTIFICATION_TYPES.SHIFT_UPDATED:
            body = `Your shift at ${location_time} has been updated.`
            if (data.creator) {
                body = `${body} Contact ${data.creator} for more information.`
            }
            break
        case NOTIFICATION_TYPES.SHIFT_OFFER_UPDATED:
            body = `Your shift offer at ${location_time} has been updated.`
            if (data.creator) {
                body = `${body} Contact ${data.creator} for more information.`
            }
            break
        case NOTIFICATION_TYPES.SHIFT_FILLED:
            body = `A shift you requested has been filled by ${employee}. Shift details: ${location_time}.`
    }

    return body
}

export {
    getNotificationDetails,
    getNotificationBodyFromEmbedData,
    NOTIFICATION_TYPES
}
