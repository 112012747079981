import { render, staticRenderFns } from "./DepartmentEvent.vue?vue&type=template&id=296c5c90&scoped=true&"
import script from "./DepartmentEvent.vue?vue&type=script&lang=js&"
export * from "./DepartmentEvent.vue?vue&type=script&lang=js&"
import style0 from "./DepartmentEvent.vue?vue&type=style&index=0&id=296c5c90&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "296c5c90",
  null
  
)

export default component.exports