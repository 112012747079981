<template>
    <v-container fluid style="background-color: #DFE0FF;">
        <v-row>
            <v-col cols="8" class="d-flex align-center">
                <div class="ml-3 text--h2" style="color: #000D60;">
                    {{ reportName }}
                </div>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            large
                            dark
                            @click="reportDetailsModal = true"
                            class="ml-3"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon style="color: #000D60;">mdi-information-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Click to view report details</span>
                </v-tooltip>
            </v-col>
            <v-spacer/>
            <v-col cols="4" class="text-right">
                <OptionsSelect
                    :key="optionsKey"
                    title="Options"
                    color="primary"
                    :items="options"
                    :disabled="isSaveAsPresetDisabled"
                    @onOptionChanged="onOptionChanged"
                    class="mr-3"
                />
            </v-col>
        </v-row>
        <save-shift-details-preset-modal
            v-model="saveAsShiftDetailsPresetModal"
            :key="saveAsShiftDetailsPresetModalKey"
            :report="report"
            @onSavePreset="onSavePreset"
        />
        <save-shift-offer-preset-modal
            v-model="saveAsShiftOffersPresetModal"
            :key="saveAsShiftOffersPresetModalKey"
            :report="report"
            @onSavePreset="onSavePreset"
        />
        <save-availability-preset-modal
            v-model="saveAsAvailabilityPresetModal"
            :key="saveAsAvailabilityPresetModalKey"
            :report="report"
            @onSavePreset="onSavePreset"
        />
        <save-shift-cancellation-preset-modal
            v-model="saveAsShiftCancellationPresetModal"
            :key="saveAsShiftCancellationPresetModalKey"
            :report="report"
            @onSavePreset="onSavePreset"
        />
        <save-payroll-period-preset-modal
            v-model="saveAsPayrollPeriodPresetModal"
            :key="saveAsPayrollPeriodPresetModalKey"
            :report="report"
            @onSavePreset="onSavePreset"
        />
        <preset-already-saved-modal v-model="presetAlreadySavedModal"/>
        <report-details-modal v-model="reportDetailsModal" :report="report"></report-details-modal>
    </v-container>
</template>

<script>
import { convertArrayToCSV } from "convert-array-to-csv"
import {
    parseReportType
} from "../../../../lib/helpers/reports"
import HeaderColumn from "./HeaderColumn"
import HeaderRow from "./HeaderRow"
import SaveShiftDetailsPresetModal from "../../Modals/Edit/ShiftDetails/SaveShiftDetailsPresetModal";
import SaveShiftOfferPresetModal from "../../Modals/Edit/ShiftOffer/SaveShiftOfferPresetModal";
import SaveAvailabilityPresetModal from "../../Modals/Edit/Availability/SaveAvailabilityPresetModal";
import SaveShiftCancellationPresetModal from "../../Modals/Edit/ShiftCancellation/SaveShiftCancellationPresetModal";
import SavePayrollPeriodPresetModal from "../../Modals/Edit/PayrollPeriod/SavePayrollPeriodPresetModal";
import PresetAlreadySavedModal from "../../Modals/PresetAlreadySavedModal"
import OptionsSelect from "../../FormInputs/OptionsSelect";
import ReportDetailsModal from "../../Modals/ReportDetailsModal";
import { formatDisplayProperty, formatEmployeeName } from '../../../../lib/helpers/reports'

export default {
    name: "ReportDetailsTableHeader",
    components: {
        SaveShiftCancellationPresetModal,
        ReportDetailsModal, OptionsSelect, HeaderColumn, HeaderRow, PresetAlreadySavedModal, SaveShiftDetailsPresetModal, SaveShiftOfferPresetModal, SaveAvailabilityPresetModal, SavePayrollPeriodPresetModal },
    props: {
        report: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        options: [
            { text: 'Save as Preset', value: 1 },
            { text: 'Export as CSV', value: 2 },
        ],
        optionsKey: 9999,
        saveAsShiftDetailsPresetModal: false,
        saveAsShiftDetailsPresetModalKey: 4444,
        saveAsShiftOffersPresetModal: false,
        saveAsShiftOffersPresetModalKey: 5555,
        saveAsAvailabilityPresetModal: false,
        saveAsAvailabilityPresetModalKey: 6666,
        saveAsShiftCancellationPresetModal: false,
        saveAsShiftCancellationPresetModalKey: 7777,
        saveAsPayrollPeriodPresetModal: false,
        saveAsPayrollPeriodPresetModalKey: 8888,
        presetAlreadySavedModal: false,
        reportDetailsModal: false,
        api: new formHelper(),
    }),
    computed: {
        reportName() {
            const type = parseReportType(this.report)
            return type === 'N/A' ? type : `${type} Report`
        },
        globalLocation() {
            return this.$root.globalLocation
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
        isSaveAsPresetDisabled() {
            return !this.$userHasPermission(this.$user, this.$config.permissions.REPORT_PRESETS.EDIT)
        }
    },
    methods: {
        onOptionChanged(option) {
            const { value } = option

            switch (value) {
                case 1:
                    if(this.report.report_preset_id) {
                        this.presetAlreadySavedModal = true
                    } else {
                        if(this.report) {
                            const { type } = this.report
                            if(type === 'shift_details') {
                                this.saveAsShiftDetailsPresetModalKey = this.saveAsShiftDetailsPresetModalKey + 1
                                this.saveAsShiftDetailsPresetModal = true
                            } else if(type === 'shift_offers') {
                                this.saveAsShiftOffersPresetModalKey = this.saveAsShiftOffersPresetModalKey + 1
                                this.saveAsShiftOffersPresetModal = true
                            } else if(type === 'user_availability') {
                                this.saveAsAvailabilityPresetModalKey = this.saveAsAvailabilityPresetModalKey + 1
                                this.saveAsAvailabilityPresetModal = true
                            } else if(type === 'shift_cancellation') {
                                this.saveAsShiftCancellationPresetModalKey = this.saveAsShiftCancellationPresetModalKey + 1
                                this.saveAsShiftCancellationPresetModal = true
                            } else if(type === 'payroll_period') {
                                this.saveAsPayrollPeriodPresetModalKey = this.saveAsPayrollPeriodPresetModalKey + 1
                                this.saveAsPayrollPeriodPresetModal = true
                            }
                        }
                    }
                    break
                case 2:
                    this.onExport()
                    break
                default:
                    this.$emit('onGoBack')
                    break
            }

            this.optionsKey = this.optionsKey + 1
        },
        onSavePreset(preset) {
            this.$emit('onSavePreset', preset)
        },
        onExport() {
            this.api.get(`/reports/details/${this.report._id}`).then(({ data }) => {
                const formattedData = data.report.data.map((item) =>
                    Object.fromEntries(data.ordered_columns.map(({ title, column_name, type }) => {
                        const data = item[column_name];

                        return [
                            title,
                            data != null && typeof data === 'object' && 'first_name' in data && 'last_name' in data
                                ? formatEmployeeName(data)
                                : formatDisplayProperty(type, data, this.globalTimezone),
                        ];
                    }))
                );

                const csv = convertArrayToCSV(formattedData);

                const $download = document.createElement('a');
                $download.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(csv)}`);
                $download.setAttribute('download', `${this.reportName}.csv`)
                $download.style.display = 'none';

                document.body.appendChild($download);
                $download.click();
                document.body.removeChild($download);
            })
        },
    }
}
</script>

<style scoped>

</style>
