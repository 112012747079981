<template>
    <preset-list
        v-model="page"
        :presets-loading="presetsLoading"
        :presets-count="presets.length"
        :number-of-pages="numberOfPages"
        :total-visible="totalVisible"
        @onChangePagination="loadPresets"
        no-data-text="The Payroll Period report provides data about Staff Member payroll period within the time period selected."
    >
        <template v-slot:preset-list-item>
            <payroll-period-preset-list-item
                v-for="(preset, index) in presets"
                :key="index"
                :preset="preset"
                @onEditPreset="onEditPreset"
                @onDeletePreset="onDeletePreset"
                @onRunQuickReport="onRunQuickReport"
            />
        </template>
        <template v-slot:edit-preset-modal>
            <edit-payroll-period-preset-modal
                v-if="editPresetModalValue"
                v-model="editPresetModalValue"
                :key="editPresetModalKey"
                :preset="selectedPreset"
                :location="location"
                report-type="payroll_period"
                @onUpdatePreset="onUpdatePreset"/>
        </template>
        <template v-slot:delete-preset-modal>
            <delete-preset-modal
                v-model="deletePresetModalValue"
                :preset="selectedPreset"
                @onDeletePreset="onDeletePresetSuccess"/>
        </template>
    </preset-list>
</template>

<script>
import PresetList from "../PresetList";
import PayrollPeriodPresetListItem from "./PayrollPeriodPresetListItem";
import EditPayrollPeriodPresetModal from "../../Modals/Edit/PayrollPeriod/EditPayrollPeriodPresetModal";
import DeletePresetModal from "../../Modals/Delete/DeletePresetModal";

export default {
    name: "PayrollPeriodPresetList",
    components: {PresetList, PayrollPeriodPresetListItem, EditPayrollPeriodPresetModal, DeletePresetModal},
    props: {
        location: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        presets: [],
        presetsLoading: false,
        page: 1,
        numberOfPages: 0,
        totalVisible: 5,
        perPage: 5,
        api: new formHelper(),
        editPresetModalKey: 9999,
        editPresetModalValue: false,
        selectedPreset: null,
        deletePresetModalValue: false,
    }),
    methods: {
        loadPresets() {
            this.presetsLoading = true
            this.api.post('/report-preset/list', this.getOptions())
                .then(({data}) => {
                    if (Array.isArray(data.data.data)) {
                        this.presets = Array.from(data.data.data)
                        this.presetsCount = data.data.total
                        if (this.presetsCount > 0) {
                            this.page = data.data.current_page
                            this.numberOfPages = data.data.last_page
                        } else {
                            this.page = 1
                            this.numberOfPages = 0
                        }
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    this.presetsLoading = false
                })
        },
        getOptions() {
            const {page, perPage} = this
            return {
                page,
                perPage,
                sortBy: 'created_at',
                sortDesc: 'desc',
                filters: {
                    report_type: 'payroll_period'
                }
            }
        },
        onEditPreset(preset) {
            this.selectedPreset = {...preset}
            this.editPresetModalKey = this.editPresetModalKey + 1
            this.editPresetModalValue = true
        },
        onUpdatePreset() {
            this.presets = []
            this.presetsCount = 0
            this.loadPresets()
            this.$emit('onUpdatePreset')
        },
        onDeletePreset(preset) {
            this.selectedPreset = {...preset}
            this.deletePresetModalValue = true
        },
        onDeletePresetSuccess() {
            this.presets = []
            this.presetsCount = 0
            this.loadPresets()
            this.$emit('onDeletePreset')
        },
        onRunQuickReport(preset) {
            const reportType = {text: 'Payroll Period', value: 'payroll_period'}
            this.$emit('onRunQuickReport', preset, reportType)
        }
    },
    mounted() {
        this.loadPresets()
    }
}
</script>

<style scoped>
</style>
