<template>
    <div class="d-inline" :style="containerStyle">
        <span class="text-uppercase primary--text font-weight-medium event-status">{{ text }}</span>
    </div>
</template>

<script>
export default {
    name: "EventStatus",
    props: {
        text: {
            type: String,
            default: ''
        },
        innerColor: {
            type: String,
            default: 'black'
        },
        outerColor: {
            type: String,
            default: 'black'
        }
    },
    data() {
        return {
            containerStyle: {
                backgroundColor: this.innerColor,
                border: `2px solid ${this.outerColor}`,
                padding: '2px 60px'
            },
        }
    }
}
</script>

<style scoped>

</style>
