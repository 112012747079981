<template>

    <v-card>
        <v-card-actions class="d-flex align-center justify-space-between" style="height: 80px; background-color: #DFE0FF;">
            <v-btn-toggle
                dusk="dateRangeTypeButton"
                v-model="type"
                color="secondary"
                mandatory
                @change="onDateChange"
            >
                <v-btn value="week" :disabled="loading">
                    Weekly
                </v-btn>
                <v-btn value="month" :disabled="loading">
                    Monthly
                </v-btn>
            </v-btn-toggle>
            <v-card-actions>
                <v-btn
                    dusk="previousDateRangeButton"
                    icon
                    class="ma-2"
                    @click="onPrev"
                    :disabled="loading"
                >
                    <v-icon style="color: #000D60;">mdi-chevron-left</v-icon>
                </v-btn>
                <span dusk="currentDateRange" class="text--h1 text-center mx-5" style="color: #000D60;">{{ calendarTitle }}</span>
                <v-btn
                    dusk="nextDateRangeButton"
                    icon
                    class="ma-2"
                    @click="onNext"
                    :disabled="loading"
                >
                    <v-icon style="color: #000D60;">mdi-chevron-right</v-icon>
                </v-btn>
            </v-card-actions>
            <v-btn-toggle
                dusk="eventTypeFilterButton"
                v-model="event_type"
                color="secondary"
                mandatory
                @change="onEventTypeChange"
            >
                <v-btn value="all" :disabled="loading">
                    All
                </v-btn>
                <v-btn value="shift" :disabled="loading">
                    Assigned
                </v-btn>
                <v-btn v-if="authUserAllowShiftOffers" value="shift_offer" :disabled="loading">
                    Offered
                </v-btn>
                <v-btn v-if="authUserAllowOpenShifts" value="open_shift" :disabled="loading">
                    Open
                </v-btn>
            </v-btn-toggle>
        </v-card-actions>
        <v-card-text>
            <div id="calendar-wrapper">
                <div v-if="loading" id="calendar-loading-overlay">
                    <span><v-progress-circular color="primary" indeterminate></v-progress-circular></span>
                </div>

                <v-calendar
                    ref="calendar"
                    v-model="value"
                    :key="calendarKey"
                    :type="type"
                    :events="parsedEvents"
                    :event-name="getEventName"
                    :event-color="getEventColor"
                    :interval-minutes="120"
                    :interval-count="12"
                    :weekdays="[0, 1, 2, 3, 4, 5, 6]"
                    @click:event="showShiftModal"
                    @click:more="showDayDetails"
                    id="shifts-calendar"
                >
                    <template v-slot:day-label="{ date, day }">
                        {{ getDayLabel(day, date) }}
                    </template>
                    <template v-slot:day-label-header="{ date, day }">
                        <div class="black--text py-2">{{ getDayLabel(day, date) }}</div>
                    </template>
                </v-calendar>
            </div>
        </v-card-text>
        <ShiftModal
            v-if="modalEvent"
            v-model="showModal"
            :event="modalEvent"
            @onOpenShiftClaim="onOpenShiftClaim"
            @onShiftOfferAccepted="onShiftOfferAccepted"
            @onShiftOfferAcceptFailed="onShiftOfferAcceptFailed"
            @onShiftOfferRejected="onShiftOfferRejected"
            @onRequestShiftCancellation="onRequestShiftCancellation"
            @closeModal="closeShiftModal">
        </ShiftModal>
        <daily-shifts-list
            v-if="eventsForDay"
            v-model="showEventsForDayModal"
            :events="eventsForDay"
            :date="dayModalDate"
            @eventClicked="showShiftModal"
            @closeDailyShiftsListModal="closeDayDetailsModal"
        ></daily-shifts-list>
    </v-card>
</template>

<script>
    import ShiftModal from './ShiftModal'
    import DailyShiftsList from "./DailyShiftsList";

    export default {
        name: "Calendar",
        components: { DailyShiftsList, ShiftModal },
        props: {
            events: {
                type: Array,
                default: function() {
                    return []
                }
            },
            calendarType: {
                type: String,
                default: 'month'
            },
            calendarTitle: {
                type: String,
                default: ''
            },
            selectedEvent: {
                type: String,
                default: null
            },
            loading: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            const showModal = !!this.selectedEvent
            const modalEvent = this.events.find(event => this.getEventId(event) === this.selectedEvent)
            const fromTime = modalEvent ? moment(modalEvent.start).tz(this.$root.globalTimezone) : moment().tz(this.$root.globalTimezone)
            const value = fromTime.format('YYYY-MM-DD')
            return {
                value,
                type: 'month',
                typeToLabel: {
                    week: 'Weekly',
                    month: 'Monthly'
                },
                event_type: 'all',
                eventTypeToLabel: {
                    all: 'All',
                    shift: 'Assigned',
                    shift_offer: 'Offered',
                    open_shift: 'Open'
                },
                colors: {
                    'open shift' : '#C8E6FF',
                    'accepted' : '#6BC96E',
                    'requested' : '#F2C266',
                    'pending cancellation': '#6BC96E',
                    'filled': '#6BC96E'
                },
                emitEvent: null,
                showModal,
                modalEvent,
                calendarKey: 0,
                showEventsForDayModal: false,
                eventsForDay: null,
                dayModalDate: null,

            }
        },
        computed: {
            globalLocation() {
                return this.$root.globalLocation
            },

            globalTimezone() {
                return this.$root.globalTimezone
            },

            parsedEvents: function() {
                return this.events
                    .filter(event => event.status !== 'rejected')
                    .map(event => ({
                        ...event,
                        start:  moment(event.start).tz(this.globalTimezone).format('YYYY-MM-DD HH:mm'),
                        end: moment(event.end).tz(this.globalTimezone).format('YYYY-MM-DD HH:mm'),
                        id: this.getEventId(event),
                        // Why are we doing this the way we do?
                        // event_type: event.status == 'open_shift' ? 'open_shift' : event.code !== null ? 'shift_offer' : 'shift'
                        event_type: event.shift_id !== null ? 'shift' : event.shift_request_id !== null ? 'open_shift' : 'shift_offer'
                    }))
            },

            authUserAllowShiftOffers() {
                return Boolean(this.$user.staff_type?.out_of_work_hours_shift_offer_enabled)
            },

            authUserAllowOpenShifts() {
                return Boolean(this.$user.staff_type?.working_hours_self_assign_enabled) || Boolean(this.$user.staff_type?.out_of_work_hours_self_assign_enabled)
            }
        },
        methods: {
            getEventColor: function(event) {
                return this.colors[event.status]
            },
            getEventName: function(event) {
                return `${event.input.department.name} - ${event.input.staff_group.name} - ${event.input.location.name}`
            },
            getEventId: function(event) {
                const { shift_id, shift_request_id, shift_offer_id } = event
                return event.type === 'shift' ? shift_id : event.type === 'open_shift' ? shift_request_id : shift_offer_id;
            },
            onPrev: function() {
                this.$refs.calendar.prev()
                this.emitEvent = {
                    event: 'onCalendarPrev',
                    refArgs: {
                        start: 'lastStart',
                        end: 'lastEnd'
                    },
                    otherArgs: {
                        type: this.type
                    }
                }
            },
            onNext: function() {
                this.$refs.calendar.next()
                this.emitEvent = {
                    event: 'onCalendarNext',
                    refArgs: {
                        start: 'lastStart',
                        end: 'lastEnd'
                    },
                    otherArgs: {
                        type: this.type
                    }
                }
            },
            onDateChange(value) {
                if (value === 'week') {
                    this.onWeeklyChange()
                } else if (value === 'month') {
                    this.onMonthlyChange()
                }
            },
            onWeeklyChange: function() {
                this.type = 'week'
                this.emitEvent = {
                    event: 'onCalendarWeekly',
                    refArgs: {
                        start: 'lastStart',
                        end: 'lastEnd'
                    }
                }
            },
            onMonthlyChange: function() {
                this.type = 'month'
                this.emitEvent = {
                    event: 'onCalendarMonthly',
                    refArgs: {
                        start: 'lastStart',
                        end: 'lastEnd'
                    }
                }
            },

            onEventTypeChange(event_type) {
                this.event_type = event_type
                this.emitEvent = {
                    event: 'onEventTypeChange',
                    refArgs: {
                        start: 'lastStart',
                        end: 'lastEnd'
                    },
                    otherArgs: {
                        event_type: event_type,
                        type: this.type
                    }
                }

                this.emitChanges()
            },

            showDayDetails({date}) {
                this.eventsForDay = this.parsedEvents.filter(event => {
                    if (moment(event.start).format('YYYY-MM-DD') === date) {
                        return true
                    }

                    return moment(event.end).format('YYYY-MM-DD') === date
                })

                this.dayModalDate = date
                this.showEventsForDayModal = true
            },

            closeDayDetailsModal() {
                this.showEventsForDayModal = false
                this.eventsForDay = null
                this.dayModalDate = null
            },

            showShiftModal: function({event}) {
                this.modalEvent = this.events.find(e => this.getEventId(e) === event.id)
                this.showModal = true
            },
            closeShiftModal: function() {
                this.showModal = false
                this.modalEvent = null
                this.$emit('onCloseModal')
            },
            onOpenShiftClaim: function(id) {
                this.$emit('onOpenShiftClaim', id)
                if (this.showEventsForDayModal) {
                    this.closeDayDetailsModal()
                }
            },
            onShiftOfferAccepted: function(id, shiftId) {
                this.$emit('onShiftOfferAccepted', id, shiftId)
            },
            onShiftOfferAcceptFailed: function({shift_offer_id, should_remove_offer}) {
                this.$emit('onShiftOfferAcceptFailed', {event_id: shift_offer_id, should_remove_offer: should_remove_offer})
            },
            onShiftOfferRejected: function(id) {
                this.$emit('onShiftOfferRejected', id)
            },
            onRequestShiftCancellation: function(id) {
                this.$emit('onRequestShiftCancellation', id)
            },
            emitChanges() {
                if (this.emitEvent) {
                    const { event, refArgs, otherArgs } = this.emitEvent
                    const refs = Object.keys(refArgs).reduce((accumulator, key) => ({
                        ...accumulator,
                        [key]: this.$refs.calendar[refArgs[key]]
                    }), {})
                    this.$emit(event, {...refs, ...otherArgs})
                    this.emitEvent = null
                }
            },
            getDayLabel(day, date) {
                if (day != 1) {
                    return day
                }

                return moment(date).format('MMM D')
            }
        },
        updated() {
            this.emitChanges()
        },
        watch: {
            events: function() {
                if(this.modalEvent) {
                    const id = this.modalEvent.shift_offer_id ? this.modalEvent.shift_offer_id : this.modalEvent.shift_id ? this.modalEvent.shift_id : this.modalEvent.shift_request_id
                    const findEvent = this.events.find(event => this.getEventId(event) === id)
                    if(findEvent) {
                        this.showModal = true
                        this.modalEvent = findEvent
                    }
                }
            },
            selectedEvent: function(newSelectedEvent) {
                const findEvent = this.events.find(event => this.getEventId(event) === newSelectedEvent)
                if(findEvent) {
                    this.showModal = true
                    this.modalEvent = findEvent
                }
            }
        }
    }
</script>

<style scoped>
    #calendar-wrapper {
        position: relative;
    }

    #calendar-loading-overlay {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.15);
        z-index: 2;
    }

    #calendar-loading-overlay span {
        position: absolute;
        top: 50%;
        left: 50%;
    }

    #shifts-calendar {
        height: 72vh;
    }

    #shifts-calendar >>> .v-calendar-daily_head-weekday {
        color: black !important;
    }

    #shifts-calendar >>> .v-calendar-weekly__head-weekday, #shifts-calendar >>> .v-calendar-weekly__head-weekday.v-past {
        color: black !important;
    }

    #shifts-calendar >>> .v-calendar-weekly__day-label, #shifts-calendar >>> .v-calendar-daily_head-day-label {
        cursor: default !important;
    }

</style>
