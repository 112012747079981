<template>
    <v-dialog v-model="value" scrollable max-width="550px" persistent>
        <v-card dusk="shiftModal">
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title class="text--h2">
                    <span v-if="isEventPending">
                        Shift Offer Details
                    </span>
                    <span v-else-if="canRequestCancellation || isPendingCancellation || isEventFilled || cancellationDeclined">
                        Shift Details
                    </span>
                    <span v-else-if="isEventOpen">
                        Shift Available
                    </span>
                    <span v-else>
                        Details
                    </span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="entity_id" icon @click="copy_entity_id" title="Copy Shift ID">
                    <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn dusk="closeShiftModalButton" icon @click="closeModal" :disabled="isRejecting || isAccepting || isRequesting || isClaiming">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
                <input type="hidden" :value="entity_id" :id="'id-to-copy-' + entity_id">
            </v-toolbar>
            <v-card-text v-if="event">
                <event-details
                    :date="event_date"
                    :time="event_time"
                    :job-title="event.job_title"
                    :location="event.location.name"
                    :staff-group="event.staff_group.name"
                    :department="event.department.name"
                    :duration="event_duration"
                    :address="event.primary_address"
                    :requester="requested_by"
                    :status="event.status"
                ></event-details>
                <v-row v-if="isEventPending">
                    <v-col class="text-right">
                        <v-btn
                            dusk="rejectShiftOfferButton"
                            name="reject"
                            color="error"
                            text
                            :disabled="isRejecting || eventStarted || isAccepting || isClaiming"
                            :loading="isRejecting"
                            @click="onRejectShiftOffer"
                        >
                            Reject
                        </v-btn>
                        <v-btn
                            dusk="acceptShiftOfferButton"
                            name="accept"
                            color="primary"
                            outlined
                            :disabled="isAccepting || eventStarted || isRejecting || isClaiming"
                            :loading="isAccepting"
                            class="ml-3"
                            @click="onAcceptShiftOffer"
                        >
                            Accept
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="isEventOpen && !isEventFilled">
                    <v-col class="text-right">
                        <v-btn
                            dusk="claimShiftOfferButton"
                            name="claim"
                            color="primary"
                            :disabled="isAccepting || eventStarted || isRejecting || isClaiming"
                            :loading="isClaiming"
                            class="ml-3"
                            @click="onOpenShiftClaim"
                        >
                            Claim Shift
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-else-if="canRequestCancellation && !eventStarted">
                    <v-col>
                        <v-btn
                            dusk="requestCancellationButton"
                            name="request-cancellation"
                            color="error"
                            text
                            :disabled="isRequesting || !userHasPermissionToRequestCancellation || isShiftStartInXHours"
                            :loading="isRequesting"
                            @click="onRequestShiftCancellation"
                        >
                            Request Cancellation
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import EventDetails from "../../components/common/EventDetails/EventDetails";
    export default {
        name: "ShiftModal",
        components: {EventDetails},
        props: {
            value: {
                default: false,
                type: Boolean
            },
            event: {
                type: Object,
                default: function() {
                    return {

                    }
                }
            }
        },
        data: function() {
            return {
                shiftOffersApi: new formHelper(),
                shiftsClaimApi: new formHelper(),
                shiftsApi: new formHelper(),
                isRejecting: false,
                isAccepting: false,
                isRequesting: false,
                isClaiming: false,
            }
        },
        methods: {
            closeModal: function() {
                this.$emit('closeModal')
            },
            onOpenShiftClaim: function() {
                this.isClaiming = true
                const { shift_request_id } = this.event
                this.shiftsClaimApi.post(`/shifts/claim-shift/${shift_request_id}`)
                    .then(({data, message}) => {
                        this.isClaiming = false
                        message('Shift Claimed Successfully.')
                        this.$emit('onOpenShiftClaim', shift_request_id, data._id)
                    })
                    .catch(() => {
                        this.isClaiming = false
                    })
            },
            onAcceptShiftOffer: function() {
                this.isAccepting = true
                const { shift_offer_id } = this.event
                this.shiftOffersApi.post('/shift-offer/accept/' + shift_offer_id)
                    .then(({data, message}) => {
                        this.isAccepting = false
                        message('Shift offer accepted.')
                        this.$emit('onShiftOfferAccepted', shift_offer_id, data._id)
                    })
                    .catch(({data}) => {
                        this.isAccepting = false
                        if (typeof data.should_remove_offer !== 'undefined' && data.should_remove_offer) {
                            this.$emit('onShiftOfferAcceptFailed', {shift_offer_id: data.shift_offer._id, should_remove_offer: data.should_remove_offer})
                            this.closeModal()
                        }
                    })
            },
            onRejectShiftOffer: function() {
                this.isRejecting = true
                const { shift_offer_id } = this.event
                this.shiftOffersApi.post('/shift-offer/reject/' + shift_offer_id)
                    .then(({data, message}) => {
                        this.isRejecting = false
                        message('Shift offer rejected.')
                        this.$emit('onShiftOfferRejected', shift_offer_id)
                    })
                    .catch(() => {
                        this.isRejecting = false
                    })
            },
            onRequestShiftCancellation: function() {
                this.isRequesting = true
                const { shift_id } = this.event
                this.shiftsApi.post(`/shifts/request-cancellation/${shift_id}`)
                    .then(({data, message}) => {
                        this.isRequesting = false
                        this.$snackNotify('warning', 'Cancelation request submitted. Shift assigned until request is approved.')
                        this.$emit('onRequestShiftCancellation', shift_id)
                    })
                    .catch(({data, message}) => {
                        if(message && typeof message === 'function' && data.message) {
                            message(data.message);
                        }
                        this.isRequesting = false
                    })
            },
            copy_entity_id() {

                if (this.isEventPending) {
                    if (typeof this.event.shift_offer_id === 'undefined') {
                        return false
                    } else {
                        if (typeof this.event.shift_id === 'undefined') {
                            return false
                        }
                    }
                }
                if (this.isEventOpen) {
                    if (typeof this.event.shift_request_id === 'undefined') {
                        return false
                    }
                }

                const ID = this.isEventPending ? this.event.shift_offer_id : this.isEventOpen ? this.event.shift_request_id : this.event.shift_id;

                let copy_field = document.querySelector('#id-to-copy-' + ID)

                copy_field.setAttribute('type', 'text')
                copy_field.focus()
                copy_field.select()

                try {
                    let success = document.execCommand('copy')
                    if (success) {
                        this.$snackNotify('success', `Shift ID copied to your clipboard`)
                    }
                } catch (err) {
                    this.$snackNotify('warning', 'Unable to copy the shift ID. Please try again.')
                }

                copy_field.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges()
            },
        },
        computed: {
            globalLocation() {
                return this.$root.globalLocation
            },
            globalTimezone() {
                return this.$root.globalTimezone
            },
            event_date: function() {
                return moment(this.event.start).tz(this.globalTimezone).format('dddd, MMMM D')
            },
            event_time: function() {
                return `${moment(this.event.start).tz(this.globalTimezone).format('LT')} - ${moment(this.event.end).tz(this.globalTimezone).format('LT')}`
            },
            event_duration: function() {
                return this.$options.filters.timeStringFromMinutes(this.event.duration)
            },
            requested_by: function() {
                const { first_name, last_name } = this.event.requested_by
                return `${first_name} ${last_name}`
            },
            isEventFilled: function() {
                return this.event.status === 'filled'
            },
            isEventPending: function() {
                return this.event.status === 'requested'
            },
            isEventOpen: function() {
                return this.event.status === 'open shift'
            },
            isPendingCancellation: function() {
                return this.event.status === 'pending cancellation'
            },
            cancellationDeclined: function() {
                return this.event.status === 'accepted' &&
                    (typeof this.event.has_declined_cancellation_request && this.event.has_declined_cancellation_request === true)
            },
            canRequestCancellation: function() {
                return this.event.status === 'accepted' &&
                    (typeof this.event.has_declined_cancellation_request === undefined || this.event.has_declined_cancellation_request === false)
            },
            eventStarted: function() {
                if(this.event && this.event.start) {
                    const now = moment()
                    const eventStart = moment(this.event.start)
                    return eventStart.isSameOrBefore(now)
                }
            },
            isShiftStartInXHours: function() {
                if (this.event && this.event.start) {
                    const eventDate = moment(this.event.start).tz(this.globalTimezone);
                    const twentyFourHoursFromNow = moment().tz(this.globalTimezone).add(24, 'hours');
                    const result = eventDate.isBefore(twentyFourHoursFromNow);
                    return result;
                }
                return false;
            },
            userHasPermissionToRequestCancellation() {
                return this.$userHasPermission(this.$user, this.$config.permissions.SHIFTS.REQUEST_CANCELLATION)
            },
            entity_id() {
                if (this.isEventPending) {
                    if (typeof this.event.shift_offer_id === 'undefined') {
                        return false
                    } else {
                        if (typeof this.event.shift_id === 'undefined') {
                            return false
                        }
                    }
                }

                if (this.isEventOpen) {
                    if (typeof this.event.shift_request_id === 'undefined') {
                        return false
                    }
                }

                const ID = this.isEventPending ? this.event.shift_offer_id : this.isEventOpen ? this.event.shift_request_id : this.event.shift_id;

                return ID
            }
        },
    }
</script>

<style scoped>
</style>
