<template>
    <v-row>
        <v-col class="d-flex justify-center align-center">
            <blue-pending-icon class="mr-5"></blue-pending-icon>
            <event-status
                text="Available Shift"
                inner-color="rgb(128, 159, 255)"
                outer-color="rgb(50, 74, 229)"></event-status>
        </v-col>
    </v-row>
</template>

<script>
import BluePendingIcon from "../CustomIcon/BluePendingIcon";
import EventStatus from "./EventStatus";
export default {
    name: "EventStatusOpen",
    components: { BluePendingIcon, EventStatus }
}
</script>

<style scoped
    BluePendingIcon>

</style>
