let config = {
    timezone: 'UTC',
    states: ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'],
    countries: ['United States'],
    shiftColors: {
        available: 'teal darken-1',
        scheduled: 'secondary',
        requested: 'deep-purple darken-1',
        blocked: 'blue-grey darken-2',
        canceled: 'deep-orange darken-1'
    },
    permissions: {
        ADMIN: {
            ADMIN: 'admin admin'
        },
        AVAILABILITY: {
            VIEW_OWN: 'availability view_own',
            EDIT_OWN: 'availability edit_own',
            VIEW_OTHERS: 'availability view_others',
            EDIT_OTHERS: 'availability edit_others',
        },
        CUSTOM_FIELDS: {
            EDIT: 'custom_fields edit'
        },
        DEPARTMENTS: {
            VIEW: 'departments view',
            EDIT: 'departments edit',
            AUTHORIZE_STAFF_GROUP: 'departments authorize_staff_group'
        },
        JOB_TITLES: {
            EDIT: 'job_titles edit'
        },
        LOCATIONS: {
            VIEW: 'locations view',
            EDIT: 'locations edit'
        },
        ORGANIZATION: {
            VIEW: 'organization view',
            EDIT: 'organization edit'
        },
        REQUEST_FORMS: {
            VIEW: 'request_forms view',
            EDIT: 'request_forms edit',
            ADD_HISTORICAL: 'request_forms add_historical'
        },
        REQUIREMENTS: {
            EDIT: 'requirements edit'
        },
        SETTINGS: {
            VIEW: 'settings view',
            EDIT: 'settings edit'
        },
        SHIFT_OFFERS: {
            SEND: 'shift_offers send'
        },
        SHIFT_TIMES: {
            EDIT: 'shift_times edit'
        },
        SHIFTS: {
            ASSIGN: 'shifts assign',
            UNASSIGN: 'shifts unassign',
            DIRECT_ASSIGN: 'shifts direct_assign',
            HANDLE_CANCELLATION: 'shifts handle_cancellation',
            REQUEST_CANCELLATION: 'shifts request_cancellation',
            HISTORICAL_ASSIGN: 'shifts historical_assign'
        },
        STAFF_GROUPS: {
            VIEW: 'staff_groups view',
            EDIT: 'staff_groups edit',
            EDIT_REQUIREMENTS: 'staff_groups edit_requirements',
            EDIT_SHIFT_TIMES: 'staff_groups edit_shift_times'
        },
        STAFF_TYPES: {
            EDIT: 'staff_types edit'
        },
        STAFFING_AUTOMATION: {
            VIEW: 'staffing_automation view',
            EDIT: 'staffing_automation edit'
        },
        USERS: {
            VIEW_USERS_PAGE: 'users view_users_page',
            EDIT_OWN_PROFILE: 'users edit_own_profile',
            VIEW_OTHERS_PROFILE: 'users view_others_profile',
            EDIT_OTHERS_PROFILE: 'users edit_others_profile',
            VIEW_OWN_PERMISSIONS: 'users view_own_permissions',
            VIEW_OTHERS_PERMISSIONS: 'users view_others_permissions',
            EDIT_OWN_PERMISSIONS: 'users edit_own_permissions',
            EDIT_OTHERS_PERMISSIONS: 'users edit_others_permissions',
            VIEW_OWN_JOBS: 'users view_own_jobs',
            VIEW_OTHERS_JOBS: 'users view_others_jobs',
            EDIT_OWN_JOBS: 'users edit_own_jobs',
            EDIT_OTHERS_JOBS: 'users edit_others_jobs',
            VIEW_SCHEDULES_PAGE: 'users view_schedules_page',
            VIEW_EXPANDED_SCHEDULES: 'users view_expanded_schedules',
            VIEW_SHIFTS_PAGE: 'users view_shifts_page',
            IMPERSONATE: 'users impersonate'
        },
        REPORTS: {
            VIEW: 'reports view',
            GENERATE: 'reports generate'
        },
        REPORT_PRESETS: {
            EDIT: 'report_presets edit'
        }
    },
    NOTIFICATION_SETTINGS: {
        EMAIL: {
            SHIFT_OFFER_ACCEPTED: 'email shift_offer_accepted',
            SHIFT_OFFER_DECLINED: 'email shift_offer_declined',
            URGENT_SHIFT_OFFER_REMINDER: 'email urgent_shift_offer_reminder',
            URGENT_SHIFT_REMINDER: 'email urgent_shift_reminder',
            SHIFT_ASSIGN: 'email assign_shift',
            SHIFT_UNASSIGN: 'email unassigned_shift',
            SHIFT_FILLED: 'email shift_filled',
            SHIFT_OFFER: 'email shift_offer',
            SHIFT_CANCELLATION_REQUEST: 'email shift_cancellation_request',
            SHIFT_CANCELLATION_REQUEST_DECLINED: 'email shift_cancellation_request_declined',
            SHIFT_CANCELLATION_REQUEST_APPROVED: 'email shift_cancellation_request_approved',
            WEEKLY_SCHEDULE: 'email weekly_schedule',
            USER_UNASSIGNED: 'email user_unassigned',
            SHIFT_OFFER_UPDATED: 'email shift_offer_updated',
            SHIFT_UPDATED: 'email updated_shift',
            AVAILABILITY_REMINDER: 'email availability_reminder',
            OWN_STAFFING_AUTOMATION_RUN_COMPLETED: 'email own_staffing_automation_run_completed',
            MANAGED_STAFFING_AUTOMATION_RUN_COMPLETED: 'email managed_staffing_automation_run_completed',
            SHARED_REPORT: 'email shared_report',
        },
        IN_APP: {
            SHIFT_OFFER_ACCEPTED: 'in_app shift_offer_accepted',
            SHIFT_OFFER_DECLINED: 'in_app shift_offer_declined',
            URGENT_SHIFT_OFFER_REMINDER: 'in_app urgent_shift_offer_reminder',
            URGENT_SHIFT_REMINDER: 'in_app urgent_shift_reminder',
            SHIFT_ASSIGN: 'in_app assign_shift',
            SHIFT_UNASSIGN: 'in_app unassigned_shift',
            SHIFT_FILLED: 'in_app shift_filled',
            SHIFT_OFFER: 'in_app shift_offer',
            SHIFT_CANCELLATION_REQUEST: 'in_app shift_cancellation_request',
            SHIFT_CANCELLATION_REQUEST_DECLINED: 'in_app shift_cancellation_request_declined',
            SHIFT_CANCELLATION_REQUEST_APPROVED: 'in_app shift_cancellation_request_approved',
            USER_UNASSIGNED: 'in_app user_unassigned',
            SHIFT_OFFER_UPDATED: 'in_app shift_offer_updated',
            SHIFT_UPDATED: 'in_app updated_shift',
            AVAILABILITY_REMINDER: 'in_app availability_reminder',
            OWN_STAFFING_AUTOMATION_RUN_COMPLETED: 'in_app own_staffing_automation_run_completed',
            MANAGED_STAFFING_AUTOMATION_RUN_COMPLETED: 'in_app managed_staffing_automation_run_completed',
            SHARED_REPORT: 'in_app shared_report',
        },
        SMS: {
            SHIFT_OFFER_ACCEPTED: 'sms shift_offer_accepted',
            SHIFT_OFFER_DECLINED: 'sms shift_offer_declined',
            URGENT_SHIFT_OFFER_REMINDER: 'sms urgent_shift_offer_reminder',
            URGENT_SHIFT_REMINDER: 'sms urgent_shift_reminder',
            SHIFT_ASSIGN: 'sms assign_shift',
            SHIFT_UNASSIGN: 'sms unassigned_shift',
            SHIFT_FILLED: 'sms shift_filled',
            SHIFT_OFFER: 'sms shift_offer',
            SHIFT_CANCELLATION_REQUEST: 'sms shift_cancellation_request',
            SHIFT_CANCELLATION_REQUEST_DECLINED: 'sms shift_cancellation_request_declined',
            SHIFT_CANCELLATION_REQUEST_APPROVED: 'sms shift_cancellation_request_approved',
            USER_UNASSIGNED: 'sms user_unassigned',
            SHIFT_OFFER_UPDATED: 'sms shift_offer_updated',
            SHIFT_UPDATED: 'sms updated_shift',
            AVAILABILITY_REMINDER: 'sms availability_reminder',
            OWN_STAFFING_AUTOMATION_RUN_COMPLETED: 'sms own_staffing_automation_run_completed',
            MANAGED_STAFFING_AUTOMATION_RUN_COMPLETED: 'sms managed_staffing_automation_run_completed',
        },
        PUSH: {
            SHIFT_ASSIGN: 'push assign_shift',
            SHIFT_UNASSIGN: 'push unassigned_shift',
            SHIFT_OFFER: 'push shift_offer',
            SHIFT_CANCELLATION_REQUEST_DECLINED: 'push shift_cancellation_request_declined',
            SHIFT_OFFER_UPDATED: 'push shift_offer_updated',
            SHIFT_UPDATED: 'push updated_shift',
            AVAILABILITY_REMINDER: 'push availability_reminder',
        }
    }
}

export default config

export const setOptions = (options) => { config = options }
