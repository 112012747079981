<template>
    <v-container grid-list-xl fluid>
        <v-row>
            <v-col cols="12" :md="!authIsOwner ? '8' : null">
                <v-card style="min-width: 500px">
                    <v-app-bar dark color="accent">
                        <v-toolbar-title style="color: #000D60;" class="text--h2">Notifications</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-switch class="pr-2" v-model="unreadOnly" label="Unread Only" color="primary" inset :disabled="notificationsLoading"
                                  hide-details @change="onNotificationsReadOnlyChange"></v-switch>
                        <v-menu open-on-click bottom offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    color="black"
                                    style="margin-top: 4px;"
                                    v-on="on"
                                    v-bind="attrs"
                                >
                                    <v-icon dark>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-list>
                                    <v-list-item @click="onNotificationReadAllDialog">
                                        <v-list-item-content>
                                            <v-list-item-title>Read All</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="onNotificationDeleteAllDialog">
                                        <v-list-item-content>
                                            <v-list-item-title style="color: #BA1A1A;">Clear</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </v-app-bar>
                    <NotificationsList
                        :key="listKey"
                        isRemoveList
                        :notifications="notifications"
                        :notifications-loading="notificationsLoading"
                        @onNotificationClick="onNotificationClick"
                        @onNotificationDelete="onNotificationDelete"
                    ></NotificationsList>
                    <v-pagination
                        v-model="page"
                        :length="numberOfPages"
                        :page="page"
                        :total-visible="totalVisible"
                        :disabled="notificationsLoading"
                        @input="loadNotifications(false, null)"
                    ></v-pagination>
                </v-card>
            </v-col>
            <v-col v-if="!authIsOwner" cols="12" md="4">
                <v-card class="mb-4 settings-card-container">
                    <v-app-bar dark color="accent">
                        <v-toolbar-title style="color: #000D60;" class="text--h2">Settings</v-toolbar-title>
                    </v-app-bar>
                    <v-card-text>
                        <div v-for="notificationSetting in filteredNotificationSettings" :key="notificationSetting.identifier">
                            <div class="text--h4 black--text mt-3">
                                {{ notificationSetting.title }}
                                <span>
                                    <v-tooltip bottom v-if="notificationSetting.tooltip" content-class="koroid-tooltip koroid-tooltip-no-fixed-width">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" color="primary_text">mdi-information</v-icon>
                                        </template>
                                        <span>{{ notificationSetting.tooltip }}</span>
                                    </v-tooltip>
                                </span>
                            </div>

                            <v-list-item v-for="notification in notificationSetting.settings" :key="notification.identifier" class="mx-1">
                                <v-tooltip top v-if="notification.tooltip && notification.invalid" content-class="koroid-tooltip koroid-tooltip-no-fixed-width">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-action class="my-0 py-0" v-bind="attrs" v-on="on" start style="margin-right: 10px;">
                                            <v-switch
                                                color="primary"
                                                inset
                                                :name="notification.identifier"
                                                :disabled="notification.invalid || notification.disabled"
                                                :loading="notification.loading"
                                                @change="onNotificationSettingChange(notification.name)"
                                                v-model="settings[notification.name]">
                                            </v-switch>
                                        </v-list-item-action>
                                    </template>
                                    <span>{{ notification.tooltip }}</span>
                                </v-tooltip>
                                <v-list-item-action class="my-0 py-0" start style="margin-right: 10px;" v-else>
                                    <v-switch
                                        color="primary"
                                        inset
                                        :name="notification.identifier"
                                        :disabled="notification.invalid || notification.disabled"
                                        :loading="notification.loading"
                                        @change="onNotificationSettingChange(notification.name)"
                                        v-model="settings[notification.name]">
                                    </v-switch>
                                </v-list-item-action>
                                <v-list-item-title>
                                    <span class="text--label black--text">
                                        {{ notification.title }}
                                    </span>
                                </v-list-item-title>
                            </v-list-item>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="notificationDetailsModal" scrollable max-width="550px" persistent>
            <v-card>
                <v-toolbar class="informational-modal" elevation="0">
                    <v-toolbar-title class="text--h2">
                        {{ notificationDetails.title }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="onCloseNotificationDetails">
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <event-details
                        :date="notificationDetails.date"
                        :time="notificationDetails.time"
                        :job-title="notificationDetails.jobTitle"
                        :location="notificationDetails.location"
                        :staff-group="notificationDetails.staffGroup"
                        :department="notificationDetails.department"
                        :duration="notificationDetails.duration"
                        :address="notificationDetails.address"
                        :requester="notificationDetails.requester"
                        :status="notificationDetails.status"
                        :employee="notificationDetails.employee"
                    ></event-details>
                    <v-row>
                        <v-col class="text-right">
                            <v-btn
                                name="view-shifts"
                                outlined
                                color="primary"
                                :href="notificationDetails.url"
                            >
                                View Shifts
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="invalidShiftModal" max-width="450px" persistent>
            <v-card>
                <v-toolbar class="warning-modal" elevation="0">
                    <v-toolbar-title class="text--h2">
                        Shift No Longer Available
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="invalidShiftModal = false">
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="d-flex text-center justify-center align-center black--text text--paragraph" style="min-height: 130px;">
                    The shift cannot be viewed because it is no longer available
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="removeAllNotificationsDialog"
            max-width="450"
            @click:outside="onModalLeave(type='deleting')"
        >
            <v-card>
                <v-toolbar dark color="error" elevation="0">
                    <v-toolbar-title class="white--text">
                        <div class="d-flex">
                            <v-icon color="white" class="mr-3">mdi-alert</v-icon>
                            <div class="mt-1 no-word-break">
                                Delete All Notifications
                            </div>
                        </div>
                    </v-toolbar-title>
                </v-toolbar>

                <v-card-text class="mt-6 black--text">
                    <p>Are you sure you want to delete all of your notifications?</p>
                    <p>This action can't be undone.</p>
                </v-card-text>

                <v-card-actions>
                    <v-row no-gutters>
                        <v-col class="text-right">
                            <v-btn
                                class="mr-2"
                                text
                                color="primary_text"
                                @click="onModalLeave(type='deleting')"
                            >
                                No
                            </v-btn>
                            <v-btn
                                class="ml-2"
                                color="error"
                                outlined
                                @click="onNotificationDeleteAll"
                                :loading="isRemovingAll"
                            >
                                Yes
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="readAllNotificationsDialog"
            max-width="450"
            @click:outside="onModalLeave(type='reading')"
        >
            <v-card>
                <v-toolbar dark color="warning" elevation="0">
                    <v-toolbar-title class="white--text">
                        <div class="d-flex">
                            <v-icon color="white" class="mr-3">mdi-alert</v-icon>
                            <div class="mt-1 no-word-break">
                                Read All Notifications
                            </div>
                        </div>
                    </v-toolbar-title>
                </v-toolbar>

                <v-card-text class="mt-6 black--text">
                    <p>Are you sure you want to mark all of your notifications as read?</p>
                </v-card-text>

                <v-card-actions>
                    <v-row no-gutters>
                        <v-col class="text-right">
                            <v-btn
                                class="mr-2"
                                text
                                color="primary_text"
                                @click="onModalLeave(type='reading')"
                            >
                                No
                            </v-btn>
                            <v-btn
                                class="ml-2"
                                color="primary"
                                outlined
                                @click="onNotificationReadAll"
                                :loading="isReadingAll"
                            >
                                Yes
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <report-details-modal
            v-if="reportDetailsModal"
            v-model="reportDetailsModal"
            :report="reportDetails"
            view-report />
    </v-container>
</template>

<script>
    import {
        parseReportType,
        parseReportPresetName,
        parseReportDateRange,
        parseReportLocations,
        parseReportCreatedBy
    } from "../../lib/helpers/reports";
    import NotificationsList from './NotificationsList'
    import NotificationDetails from './NotificationDetails'
    import ReportDetailsModal from "../../components/reporting/Modals/ReportDetailsModal";
    import EventDetails from "../../components/common/EventDetails/EventDetails";
    import notificationUrlMixin from '../../lib/mixins/notificationUrl'
    import {NOTIFICATION_TYPES} from "../../lib/helpers/getNotificationDetails";

    export default {
        name: "index",
        components: { NotificationDetails, NotificationsList, ReportDetailsModal, EventDetails },
        mixins: [notificationUrlMixin],
        data: function() {
            const NOTIFICATION_SETTINGS = Object.assign({}, this.$config.NOTIFICATION_SETTINGS)
            const user = this.$appData.user
            const authCannotHandleSMSNotifications = !user.phone || user.unreachable_phone || user.phone_denied
            const authHasOptedOutOfSMSNotifications = !user?.twilio_opt_in === true
            const twilioNumber = this.$appData.twilio_number
            const notificationSettings = {
                EMAIL: {
                    title: 'Email Notifications',
                    identifier: 'email-notifications',
                    tooltip: null,
                    settings: {
                        SHIFT_OFFER_ACCEPTED: {
                            title: 'Shift Offer Accepted',
                            identifier: 'email-shift-offer-accepted',
                            name: NOTIFICATION_SETTINGS.EMAIL.SHIFT_OFFER_ACCEPTED,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.SHIFT_OFFERS.SEND)
                        },
                        SHIFT_OFFER_DECLINED: {
                            title: 'Shift Offer Rejected',
                            identifier: 'email-shift-offer-declined',
                            name: NOTIFICATION_SETTINGS.EMAIL.SHIFT_OFFER_DECLINED,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.SHIFT_OFFERS.SEND)
                        },
                        URGENT_SHIFT_OFFER_REMINDER: {
                            title: 'Urgent Shift Offer Reminder',
                            identifier: 'email-urgent-shift-offer-reminder',
                            name: NOTIFICATION_SETTINGS.EMAIL.URGENT_SHIFT_OFFER_REMINDER,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.SHIFT_OFFERS.SEND)
                        },
                        URGENT_SHIFT_REMINDER: {
                            title: 'Urgent Shift Reminder',
                            identifier: 'email-urgent-shift-reminder',
                            name: NOTIFICATION_SETTINGS.EMAIL.URGENT_SHIFT_REMINDER,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.SHIFTS.ASSIGN) && !this.$userHasPermission(user, this.$config.permissions.SHIFT_OFFERS.SEND)
                        },
                        SHIFT_ASSIGN: {
                            title: 'Shift Assigned',
                            identifier: 'email-shift-assigned',
                            name: NOTIFICATION_SETTINGS.EMAIL.SHIFT_ASSIGN,
                            disabled: false,
                            loading: false,
                        },
                        SHIFT_UNASSIGN: {
                            title: 'Shift Cancelled',
                            identifier: 'email-shift-cancelled',
                            name: NOTIFICATION_SETTINGS.EMAIL.SHIFT_UNASSIGN,
                            disabled: false,
                            loading: false,
                        },
                        SHIFT_FILLED: {
                            title: 'Shift Filled',
                            identifier: 'email-shift-filled',
                            name: NOTIFICATION_SETTINGS.EMAIL.SHIFT_FILLED,
                            disabled: false,
                            loading: false,
                        },
                        SHIFT_OFFER: {
                            title: 'Shift Offered',
                            identifier: 'email-shift-offered',
                            name: NOTIFICATION_SETTINGS.EMAIL.SHIFT_OFFER,
                            disabled: false,
                            loading: false,
                        },
                        SHIFT_CANCELLATION_REQUEST: {
                            title: 'Shift Cancellation Request',
                            identifier: 'email-shift-cancellation-request',
                            name: NOTIFICATION_SETTINGS.EMAIL.SHIFT_CANCELLATION_REQUEST,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.SHIFTS.HANDLE_CANCELLATION)
                        },
                        SHIFT_CANCELLATION_REQUEST_DECLINED: {
                            title: 'Shift Cancellation Request Rejected',
                            identifier: 'email-shift-cancellation-request-declined',
                            name: NOTIFICATION_SETTINGS.EMAIL.SHIFT_CANCELLATION_REQUEST_DECLINED,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.SHIFTS.REQUEST_CANCELLATION)
                        },
                        SHIFT_CANCELLATION_REQUEST_APPROVED: {
                            title: 'Shift Cancellation Request Approved',
                            identifier: 'email-shift-cancellation-request-approved',
                            name: NOTIFICATION_SETTINGS.EMAIL.SHIFT_CANCELLATION_REQUEST_APPROVED,
                            disabled: false,
                            loading: false,
                        },
                        WEEKLY_SCHEDULE: {
                            title: 'Weekly Schedule',
                            identifier: 'email-weekly-schedule',
                            name: NOTIFICATION_SETTINGS.EMAIL.WEEKLY_SCHEDULE,
                            disabled: false,
                            loading: false,
                        },
                        USER_UNASSIGNED: {
                            title: 'Shift Cancelled',
                            identifier: 'email-user-unassigned',
                            name: NOTIFICATION_SETTINGS.EMAIL.USER_UNASSIGNED,
                            disabled: false,
                            loading: false,
                        },
                        AVAILABILITY_REMINDER: {
                            title: 'Availability Reminder',
                            identifier: 'email-availability-reminder',
                            name: NOTIFICATION_SETTINGS.EMAIL.AVAILABILITY_REMINDER,
                            disabled: false,
                            loading: false,
                            hidden: !this.$authIsStaff
                        },
                        OWN_STAFFING_AUTOMATION_RUN_COMPLETED: {
                            title: 'Own Staffing Automation Run Completed',
                            identifier: 'email-own-staffing-automation-reminder',
                            name: NOTIFICATION_SETTINGS.EMAIL.OWN_STAFFING_AUTOMATION_RUN_COMPLETED,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.STAFFING_AUTOMATION.VIEW)
                        },
                        MANAGED_STAFFING_AUTOMATION_RUN_COMPLETED: {
                            title: 'Managed Staffing Automation Run Completed',
                            identifier: 'email-managed-staffing-automation-reminder',
                            name: NOTIFICATION_SETTINGS.EMAIL.MANAGED_STAFFING_AUTOMATION_RUN_COMPLETED,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.STAFFING_AUTOMATION.VIEW)
                        },
                        SHARED_REPORT: {
                            title: 'Shared Report',
                            identifier: 'email-shared-report',
                            name: NOTIFICATION_SETTINGS.EMAIL.SHARED_REPORT,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.REPORTS.VIEW)
                        }
                    }
                },
                SMS: {
                    title: 'SMS Notifications',
                    identifier: 'sms-notifications',
                    tooltip: authHasOptedOutOfSMSNotifications ? `You must text START to ${twilioNumber} to enable SMS notifications` : null,
                    settings: {
                        SHIFT_OFFER_ACCEPTED: {
                            title: 'Shift Offer Accepted',
                            identifier: 'sms-shift-offer-accepted',
                            name: NOTIFICATION_SETTINGS.SMS.SHIFT_OFFER_ACCEPTED,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.SHIFT_OFFERS.SEND),
                            tooltip: 'Invalid phone number',
                            invalid: authCannotHandleSMSNotifications
                        },
                        SHIFT_OFFER_DECLINED: {
                            title: 'Shift Offer Rejected',
                            identifier: 'sms-shift-offer-declined',
                            name: NOTIFICATION_SETTINGS.SMS.SHIFT_OFFER_DECLINED,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.SHIFT_OFFERS.SEND),
                            tooltip: 'Invalid phone number',
                            invalid: authCannotHandleSMSNotifications
                        },
                        URGENT_SHIFT_OFFER_REMINDER: {
                            title: 'Urgent Shift Offer Reminder',
                            identifier: 'sms-urgent-shift-offer-reminder',
                            name: NOTIFICATION_SETTINGS.SMS.URGENT_SHIFT_OFFER_REMINDER,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.SHIFT_OFFERS.SEND),
                            tooltip: 'Invalid phone number',
                            invalid: authCannotHandleSMSNotifications
                        },
                        URGENT_SHIFT_REMINDER: {
                            title: 'Urgent Shift Reminder',
                            identifier: 'sms-urgent-shift-reminder',
                            name: NOTIFICATION_SETTINGS.SMS.URGENT_SHIFT_REMINDER,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.SHIFTS.ASSIGN) && !this.$userHasPermission(user, this.$config.permissions.SHIFT_OFFERS.SEND),
                            tooltip: 'Invalid phone number',
                            invalid: authCannotHandleSMSNotifications
                        },
                        SHIFT_ASSIGN: {
                            title: 'Shift Assigned',
                            identifier: 'sms-shift-assigned',
                            name: NOTIFICATION_SETTINGS.SMS.SHIFT_ASSIGN,
                            disabled: false,
                            loading: false,
                            tooltip: 'Invalid phone number',
                            invalid: authCannotHandleSMSNotifications
                        },
                        SHIFT_UNASSIGN: {
                            title: 'Shift Cancelled',
                            identifier: 'sms-shift-cancelled',
                            name: NOTIFICATION_SETTINGS.SMS.SHIFT_UNASSIGN,
                            disabled: false,
                            loading: false,
                            tooltip: 'Invalid phone number',
                            invalid: authCannotHandleSMSNotifications
                        },
                        SHIFT_FILLED: {
                            title: 'Shift Filled',
                            identifier: 'sms-shift-filled',
                            name: NOTIFICATION_SETTINGS.SMS.SHIFT_FILLED,
                            disabled: false,
                            loading: false,
                            tooltip: 'Invalid phone number',
                            invalid: authCannotHandleSMSNotifications
                        },
                        SHIFT_OFFER: {
                            title: 'Shift Offered',
                            identifier: 'sms-shift-offered',
                            name: NOTIFICATION_SETTINGS.SMS.SHIFT_OFFER,
                            disabled: false,
                            loading: false,
                            tooltip: 'Invalid phone number',
                            invalid: authCannotHandleSMSNotifications
                        },
                        SHIFT_CANCELLATION_REQUEST: {
                            title: 'Shift Cancellation Request',
                            identifier: 'sms-shift-cancellation-request',
                            name: NOTIFICATION_SETTINGS.SMS.SHIFT_CANCELLATION_REQUEST,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.SHIFTS.HANDLE_CANCELLATION),
                            tooltip: 'Invalid phone number',
                            invalid: authCannotHandleSMSNotifications
                        },
                        SHIFT_CANCELLATION_REQUEST_DECLINED: {
                            title: 'Shift Cancellation Request Rejected',
                            identifier: 'sms-shift-cancellation-request-declined',
                            name: NOTIFICATION_SETTINGS.SMS.SHIFT_CANCELLATION_REQUEST_DECLINED,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.SHIFTS.REQUEST_CANCELLATION),
                            tooltip: 'Invalid phone number',
                            invalid: authCannotHandleSMSNotifications
                        },
                        SHIFT_CANCELLATION_REQUEST_APPROVED: {
                            title: 'Shift Cancellation Request Approved',
                            identifier: 'sms-shift-cancellation-request-approved',
                            name: NOTIFICATION_SETTINGS.SMS.SHIFT_CANCELLATION_REQUEST_APPROVED,
                            disabled: false,
                            loading: false,
                            tooltip: 'Invalid phone number',
                            invalid: authCannotHandleSMSNotifications
                        },
                        USER_UNASSIGNED: {
                            title: 'Shift Cancelled',
                            identifier: 'sms-user-unassigned',
                            name: NOTIFICATION_SETTINGS.SMS.USER_UNASSIGNED,
                            disabled: false,
                            loading: false,
                            tooltip: 'Invalid phone number',
                            invalid: authCannotHandleSMSNotifications
                        },
                        AVAILABILITY_REMINDER: {
                            title: 'Availability Reminder',
                            identifier: 'sms-availability-reminder',
                            name: NOTIFICATION_SETTINGS.SMS.AVAILABILITY_REMINDER,
                            disabled: false,
                            loading: false,
                            tooltip: 'Invalid phone number',
                            invalid: authCannotHandleSMSNotifications,
                            hidden: !this.$authIsStaff
                        },
                        OWN_STAFFING_AUTOMATION_RUN_COMPLETED: {
                            title: 'Own Staffing Automation Run Completed',
                            identifier: 'sms-own-staffing-automation-reminder',
                            name: NOTIFICATION_SETTINGS.SMS.OWN_STAFFING_AUTOMATION_RUN_COMPLETED,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.STAFFING_AUTOMATION.VIEW)
                        },
                        MANAGED_STAFFING_AUTOMATION_RUN_COMPLETED: {
                            title: 'Managed Staffing Automation Run Completed',
                            identifier: 'sms-managed-staffing-automation-reminder',
                            name: NOTIFICATION_SETTINGS.SMS.MANAGED_STAFFING_AUTOMATION_RUN_COMPLETED,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.STAFFING_AUTOMATION.VIEW)
                        }
                    }
                },
                PUSH: {
                    title: 'Push Notifications',
                    identifier: 'push-notifications',
                    tooltip: null,
                    settings: {
                        SHIFT_ASSIGN: {
                            title: 'Shift Assigned',
                            identifier: 'push-shift-assigned',
                            name: NOTIFICATION_SETTINGS.PUSH.SHIFT_ASSIGN,
                            disabled: false,
                            loading: false,
                        },
                        SHIFT_UNASSIGN: {
                            title: 'Shift Cancelled',
                            identifier: 'push-shift-cancelled',
                            name: NOTIFICATION_SETTINGS.PUSH.SHIFT_UNASSIGN,
                            disabled: false,
                            loading: false,
                        },
                        SHIFT_OFFER: {
                            title: 'Shift Offered',
                            identifier: 'push-shift-offered',
                            name: NOTIFICATION_SETTINGS.PUSH.SHIFT_OFFER,
                            disabled: false,
                            loading: false,
                        },
                        SHIFT_CANCELLATION_REQUEST_DECLINED: {
                            title: 'Shift Cancellation Request Rejected',
                            identifier: 'push-shift-cancellation-request-declined',
                            name: NOTIFICATION_SETTINGS.PUSH.SHIFT_CANCELLATION_REQUEST_DECLINED,
                            disabled: false,
                            loading: false,
                            hidden: !this.$userHasPermission(user, this.$config.permissions.SHIFTS.REQUEST_CANCELLATION)
                        },
                        AVAILABILITY_REMINDER: {
                            title: 'Availability Reminder',
                            identifier: 'push-availability-reminder',
                            name: NOTIFICATION_SETTINGS.PUSH.AVAILABILITY_REMINDER,
                            disabled: false,
                            loading: false,
                            hidden: !this.$authIsStaff
                        },
                    }
                }
            }
            return {
                notifications: [],
                available_notifications: this.$appData.available_notifications,
                user,
                notificationSettings,
                NOTIFICATION_SETTINGS,
                settings: {},
                unreadOnly: false,
                listKey: 0,
                page: 1,
                preselectedNotification: this.$appData.preselected_notification,
                numberOfPages: 1,
                perPage: 10,
                totalVisible: 10,
                alertsForm: new formHelper(),
                notificationsApi: new formHelper(),
                notificationsLoading: false,
                invalidShiftModal: false,
                notificationDetailsModal: false,
                reportDetailsModal: false,
                reportDetails: null,
                notificationDetails: {},
                isRemovingAll: false,
                removeAllNotificationsDialog: false,
                isReadingAll: false,
                readAllNotificationsDialog: false,
            }
        },
        computed: {
            filteredNotificationSettings() {
                let { available_notifications } = this

                if (!Array.isArray(available_notifications) || !available_notifications.length) {
                    return {}
                }

                available_notifications = this.filterAvailableNotificationsForStaffType(available_notifications)

                const { notificationSettings } = this

                const userSettings = available_notifications.reduce((settings, setting) => ({
                    ...settings,
                    [setting]: true
                }), {})

                let filteredSettings = {}
                filteredSettings = this.addNotificationSettingsToFilteredResult(filteredSettings, 'EMAIL', userSettings, notificationSettings.EMAIL)
                filteredSettings = this.addNotificationSettingsToFilteredResult(filteredSettings, 'SMS', userSettings, notificationSettings.SMS)
                filteredSettings = this.addNotificationSettingsToFilteredResult(filteredSettings, 'PUSH', userSettings, notificationSettings.PUSH)

                filteredSettings = this.configureAvailabilityReminderSettings(filteredSettings)

                return filteredSettings
            },

            OLD_filteredNotificationSettings() {
                const { available_notifications } = this
                if(Array.isArray(available_notifications) && available_notifications.length > 0) {
                    const { notificationSettings } = this
                    const userSettings = available_notifications.reduce((settings, setting) => ({
                        ...settings,
                        [setting]: true
                    }), {})
                    let result = {}
                    const emailSettings = this.filterSettings(notificationSettings.EMAIL.settings, userSettings)
                    if(Object.keys(emailSettings).length > 0) {
                        result = {
                            EMAIL: {
                                ...notificationSettings.EMAIL,
                                settings: {...emailSettings}
                            }
                        }
                    }
                    const smsSettings = this.filterSettings(notificationSettings.SMS.settings, userSettings)
                    if(Object.keys(smsSettings).length > 0) {
                        result = {
                            ...result,
                            SMS: {
                                ...notificationSettings.SMS,
                                settings: {...smsSettings}
                            }
                        }
                    }
                    const pushSettings = this.filterSettings(notificationSettings.PUSH.settings, userSettings)
                    if(Object.keys(pushSettings).length > 0) {
                        result = {
                            ...result,
                            PUSH: {
                                ...notificationSettings.PUSH,
                                settings: {...pushSettings}
                            }
                        }
                    }
                    if (this.$authIsStaff) {
                        if (this.availabilityReminders) {
                            const { selected, count } = this.availabilityReminders
                            if (selected.length === 1 && count === 1) {
                                const setting = selected[0]
                                if (setting) {
                                    const parts = setting.split(' ')
                                    if (parts.length === 2) {
                                        const type = parts[0]
                                        if (type === 'email') {
                                            if (result && result.EMAIL && result.EMAIL.settings && result.EMAIL.settings.AVAILABILITY_REMINDER) {
                                                result.EMAIL.settings.AVAILABILITY_REMINDER = {
                                                    ...result.EMAIL.settings.AVAILABILITY_REMINDER,
                                                    disabled: true,
                                                }
                                            }
                                        } else if (type === 'sms') {
                                            if (result && result.SMS && result.SMS.settings && result.SMS.settings.AVAILABILITY_REMINDER) {
                                                result.SMS.settings.AVAILABILITY_REMINDER = {
                                                    ...result.SMS.settings.AVAILABILITY_REMINDER,
                                                    disabled: true,
                                                }
                                            }
                                        } else if (type === 'push') {
                                            if (result && result.PUSH && result.PUSH.settings && result.PUSH.settings.AVAILABILITY_REMINDER) {
                                                result.PUSH.settings.AVAILABILITY_REMINDER = {
                                                    ...result.PUSH.settings.AVAILABILITY_REMINDER,
                                                    disabled: true,
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    return result
                }
                return {}
            },

            availabilityReminders () {
                if (this.$authIsStaff) {
                    const { settings, NOTIFICATION_SETTINGS } = this
                    if (settings && NOTIFICATION_SETTINGS) {
                        const keys = Object.keys(settings).filter(setting => {
                            return setting === NOTIFICATION_SETTINGS.EMAIL.AVAILABILITY_REMINDER ||
                                setting === NOTIFICATION_SETTINGS.SMS.AVAILABILITY_REMINDER ||
                                setting === NOTIFICATION_SETTINGS.PUSH.AVAILABILITY_REMINDER
                        })
                        return {
                            selected: keys.filter(setting => settings[setting]),
                            count: keys.map(setting => settings[setting]).filter(value => value).length,
                        }
                    }
                    return null
                }
                return null
            },
            notification: function() {
                return this.notifications.find(notification => notification._id === this.activeNotificationId)
            },
            authIsOwner() {
                return this.$authIsOwner
            },
            globalTimezone() {
                return this.$root.globalTimezone
            },
        },
        methods: {
            onNotificationClick: function(notificationId) {
                const notification = this.notifications.find(notification => notification._id === notificationId)
                this.onGetNotificationDetails(notification)
                this.markNotificationAsRead(notification)
            },
            onNotificationDelete: function(notificationId) {
                const notification = this.notifications.find(notification => notification._id === notificationId)
                this.loadNotifications(true, null)
                if(notification && !notification.read_at) {
                    this.updateLatestNotifications(notificationId)
                }
            },
            onNotificationReadAllDialog: function() {
                this.readAllNotificationsDialog = true
            },
            onNotificationReadAll: function() {
                this.isReadingAll = true
                this.notificationsApi.post('/notifications/mark-all-as-read')
                    .then((response) => {
                        if (response.data.message === 'success') {
                            this.$root.unreadNotificationsCount = 0
                            this.loadNotifications()
                            this.$snackNotify('success', 'All notifications marked as read.')
                        } else {
                            console.error('Failed to mark all as read:', response.data.message);
                            this.$snackNotify('warning', 'Failed to mark all notifications as read. ' + response.data.message)
                        }
                    })
                    .catch((error) => {
                        console.error('Error when trying to mark all as read:', error);
                        this.$snackNotify('error', 'Unable to read all notifications. Please try again.')
                    })
                    .finally(() => {
                        this.readAllNotificationsDialog = false
                        this.isReadingAll = false
                    })
            },
            onModalLeave: function(type) {
                switch (type) {
                    case 'deleting':
                        this.removeAllNotificationsDialog = false
                        this.isRemovingAll = false
                        break;
                    case 'reading':
                        this.readAllNotificationsDialog = false
                        this.isReadingAll = false
                        break;
                }
            },
            onNotificationDeleteAllDialog: function() {
                this.removeAllNotificationsDialog = true
            },
            onNotificationDeleteAll: function() {
                this.isRemovingAll = true
                this.notificationsApi.delete('/notifications/delete/all')
                    .then((response) => {
                        if (response.data.message === 'success') {
                            this.$root.unreadNotificationsCount = 0
                            this.loadNotifications()
                            this.$snackNotify('success', 'All notifications deleted.')
                        } else {
                            console.error('Failed to mark all as read:', response.data.message);
                            this.$snackNotify('warning', 'Failed to mark all notifications as read. ' + response.data.message)
                        }
                    })
                    .catch((error) => {
                        console.error('Error when trying to delete all notifications:', error);
                        this.$snackNotify('error', 'Unable to delete all notifications. Please try again.')
                    }).finally(() => {
                        this.removeAllNotificationsDialog = false
                        this.isRemovingAll = false
                    })
            },
            markNotificationAsRead: function(notification) {
                if(notification) {
                    if(!notification.read_at) {
                        const notificationId = notification._id
                        if(notificationId) {
                            if(this.$root.notifications.some(n => n._id === notificationId)) {
                                this.$root.loadingLatestNotifications = true
                            }
                            this.notificationsApi.post('/notifications/mark-as-read/' + notificationId)
                                .then(() => {
                                    this.markNotification(notificationId, 'read_at', moment().format('YYYY-MM-DD HH:mm:ss'))
                                    this.updateLatestNotifications(notificationId)
                                })
                                .catch(() => {
                                    this.$root.loadingLatestNotifications = false
                                })
                        }
                    }
                }
            },
            updateLatestNotifications(notificationId) {
                if(this.$root.notifications.some(n => n._id === notificationId)) {
                    this.$root.loadingLatestNotifications = true
                    this.notificationsApi.get('/latest-notifications')
                        .then(({data}) => {
                            this.$root.notifications = Array.from(data)
                            this.$root.unreadNotificationsCount = this.$root.unreadNotificationsCount - 1
                            this.$root.loadingLatestNotifications = false
                        })
                        .catch(() => {
                            this.$root.loadingLatestNotifications = false
                        })
                } else {
                    this.$root.loadingLatestNotifications = false
                    this.$root.unreadNotificationsCount = this.$root.unreadNotificationsCount - 1
                }
            },
            onGetNotificationDetails(notification) {
                if(notification) {
                    if(notification.valid) {
                        this.getNotificationDetails(notification._id)
                    } else {
                        this.invalidShiftModal = true
                    }
                } else {
                    this.invalidShiftModal = true
                }
            },
            getNotificationDetails(notificationId) {
                if(notificationId) {
                    this.markNotification(notificationId, 'loading', true)
                    this.notificationsApi.get('/notifications/' + notificationId)
                        .then((data) => {
                            this.markNotification(notificationId, 'loading', false)
                            const notification = data.data
                            if (notification.report) {
                                this.reportDetails = this.parseReport(notification.report)
                                this.reportDetailsModal = true
                            }
                            else if (notification.shift || (notification.shift_offer && notification.shift_offer.shift_request)) {
                                const notificationDetails = this.parseNotificationDetails(notification)
                                this.notificationDetails = { ...notificationDetails }
                                this.notificationDetailsModal = true
                            } else if (notification.data.type === NOTIFICATION_TYPES.AVAILABILITY_REMINDER) {
                                window.location.href = 'profile?tab=availability'
                            } else {
                                this.invalidShiftModal = true
                            }
                        })
                        .catch((data) => {
                            this.markNotification(notificationId, 'loading', false)
                            this.invalidShiftModal = true
                        })
                } else {
                    this.invalidShiftModal = true
                }
            },
            parseNotificationDetails(notification) {
                const shiftDetails = notification.shift ? notification.shift : notification.shift_offer.shift_request
                const start = moment(shiftDetails.start).tz(this.globalTimezone)
                const end = moment(shiftDetails.end).tz(this.globalTimezone)
                const { first_name, last_name } = shiftDetails.created_by

                let employee = null
                if (!this.$authIsStaff && (typeof shiftDetails.employee !== 'undefined' && shiftDetails.employee)) {
                    employee = shiftDetails.employee.first_name + " " + shiftDetails.employee.last_name
                }

                let status, title
                if(notification.data.type === NOTIFICATION_TYPES.CANCEL_SHIFT_REQUESTED) {
                    title = 'Shift Details'
                    status = 'pending cancellation'
                } else if(notification.data.type === NOTIFICATION_TYPES.OFFER_SHIFT) {
                    status = 'requested'
                    title = 'Shift Offer Details'
                } else if (notification.data.type === NOTIFICATION_TYPES.CANCEL_SHIFT_APPROVED) {
                    status = 'open'
                    title = 'Shift Details'
                } else {
                    title = 'Shift Details'
                    status = 'accepted'
                }
                return {
                    title,
                    date: start.format('dddd, MMMM D'),
                    time: `${start.format('LT')} - ${end.format('LT')}`,
                    jobTitle: shiftDetails.job_title_name,
                    location: shiftDetails.location_name,
                    staffGroup: shiftDetails.staff_group_name,
                    department: shiftDetails.department_name,
                    duration: this.$options.filters.timeStringFromMinutes(shiftDetails.duration),
                    address: shiftDetails.location.primary_address,
                    requester: `${first_name} ${last_name}`,
                    status,
                    employee,
                    url: this.buildUrlForNotification(notification)
                }
            },
            onNotificationsReadOnlyChange: function() {
                this.loadNotifications(true, null)
            },
            markNotification(notificationId, key, value) {
                this.notifications = this.notifications.map(notification => {
                    if(notification._id === notificationId) {
                        return {
                            ...notification,
                            [key]: value
                        }
                    }
                    return notification
                })
            },
            onCloseNotificationDetails() {
                this.notificationDetails = {}
                this.notificationDetailsModal = false
            },
            loadNotifications(fresh, preselectedNotificationId = null) {
                if(fresh) {
                    this.page = 1
                }
                this.notificationsLoading = true
                this.alertsForm.post(`/notifications/list/${this.user._id}`, {
                    page: this.page,
                    perPage: this.perPage,
                    ...this.unreadOnly && { filters: { read_status: 'unread' } }
                }).then(({data}) => {
                    this.notifications = data.data
                    if(this.notifications.length > 0) {
                        this.page = data.current_page
                        this.numberOfPages = data.last_page
                    } else {
                        this.page = 1
                        this.numberOfPages = 0
                    }
                    this.notificationsLoading = false
                    if(preselectedNotificationId) {
                        this.onNotificationClick(preselectedNotificationId)
                    }
                })
                .catch(() => {
                    this.notificationsLoading = false
                })
            },
            onNotificationSettingChange(setting) {
                const { settings } = this
                const newSettings = Object.keys(settings).filter(setting => settings[setting])
                this.disableNotificationSettings(setting, true)
                this.alertsForm.post('/user/change-notification-settings', {
                    user_id: this.user._id,
                    notification_settings: newSettings
                })
                    .then( ({message}) => {
                        this.disableNotificationSettings(setting, false)
                        message('Changes to notification settings saved.')
                        this.$appData.currentUser = {
                            ...this.$appData.currentUser,
                            notification_settings: Array.from(newSettings)
                        }
                    })
                .catch(() => {
                    this.disableNotificationSettings(setting, false)
                })
            },
            disableNotificationSettings(setting, disable) {
                const { notificationSettings } = this
                this.notificationSettings = {
                    EMAIL: {
                        ...notificationSettings.EMAIL,
                        settings: this.updateSettings(notificationSettings.EMAIL.settings, setting, disable, disable)
                    },
                    SMS: {
                        ...notificationSettings.SMS,
                        settings: this.updateSettings(notificationSettings.SMS.settings, setting, disable, disable)
                    },
                    PUSH: {
                        ...notificationSettings.PUSH,
                        settings: this.updateSettings(notificationSettings.PUSH.settings, setting, disable, disable)
                    },
                }
            },
            filterSettings(appSettings, userSettings) {
                return Object.keys(appSettings).reduce((settings, setting) => {
                    if(userSettings.hasOwnProperty(appSettings[setting].name)) {
                        if(appSettings[setting].hidden) {
                            return settings
                        }
                        return {
                            ...settings,
                            [setting]: appSettings[setting]
                        }
                    }
                    return settings
                }, {})
            },
            updateSettings(settings, setting, disabled = true, loading = true) {
                return Object.keys(settings).reduce((result, value) => ({
                    ...result,
                    [value]: {
                        ...settings[value],
                        disabled,
                        loading: settings[value].name === setting ? loading : false
                    }
                }), {})
            },
            buildSettings() {
                const { notification_settings } = this.user
                const { notificationSettings } = this
                let userSettings = {}
                if(Array.isArray(notification_settings) && notification_settings.length > 0) {
                    userSettings = notification_settings.reduce((settings, setting) => ({
                        ...settings,
                        [setting]: true
                    }), {})
                }
                const emailSettings = Object
                    .keys(notificationSettings.EMAIL.settings)
                    .reduce((settings, setting) => ({
                        ...settings,
                        [notificationSettings.EMAIL.settings[setting].name]: userSettings.hasOwnProperty(notificationSettings.EMAIL.settings[setting].name)
                    }), {})
                const smsSettings = Object
                    .keys(notificationSettings.SMS.settings)
                    .reduce((settings, setting) => ({
                        ...settings,
                        [notificationSettings.SMS.settings[setting].name]: userSettings.hasOwnProperty(notificationSettings.SMS.settings[setting].name)
                    }), {})
                const pushSettings = Object
                    .keys(notificationSettings.PUSH.settings)
                    .reduce((settings, setting) => ({
                        ...settings,
                        [notificationSettings.PUSH.settings[setting].name]: userSettings.hasOwnProperty(notificationSettings.PUSH.settings[setting].name)
                    }), {})
                this.settings = {
                    ...emailSettings,
                    ...smsSettings,
                    ...pushSettings
                }
            },
            parseReport(report) {
                return {
                    ...report,
                    report_type_name: parseReportType(report),
                    preset_name: parseReportPresetName(report),
                    date_range: parseReportDateRange(
                        report,
                        report.type === 'payroll_period' && report.hospital_group?.timezone ?
                            report.hospital_group.timezone : this.globalTimezone),
                    displayed_created_by: parseReportCreatedBy(report),
                    displayedFilters: {
                        displayedLocations: parseReportLocations(report)
                    },
                    created_at: moment(report.created_at).tz(this.globalTimezone).format('LLL')
                }
            },
            filterAvailableNotificationsForStaffType(available_notifications) {
                if (
                    typeof this.$user.staff_type === 'undefined' ||
                    !this.$user.staff_type ||
                    typeof this.$user.staff_type.work_schedule_config === 'undefined' ||
                    !this.$user.staff_type.work_schedule_config
                ) {
                    return available_notifications
                }

                if (this.$user.staff_type.work_schedule_config === 'user_defined') {
                    return available_notifications
                }

                return available_notifications.filter(notification => {
                    let notification_parts = notification.split(" ")

                    if (notification_parts.length !== 2) {
                        return false
                    }

                    return notification_parts[1] !== 'availability_reminder'
                })
            },

            addNotificationSettingsToFilteredResult(filteredNotifications, key, userSettings, notificationSettings) {
                const settings = this.filterSettings(notificationSettings.settings, userSettings)

                if (Object.keys(settings).length) {
                    filteredNotifications = {
                        ...filteredNotifications,
                        [key]: {
                            ...notificationSettings,
                            settings: settings
                        }
                    }
                }

                return filteredNotifications
            },

            configureAvailabilityReminderSettings(filteredSettings) {
                if (!this.$authIsStaff || !this.availabilityReminders) {
                    return filteredSettings
                }

                const { selected, count } = this.availabilityReminders
                if (selected.length !== 1 || count !== 1) {
                    return filteredSettings
                }

                const setting = selected[0]
                if (!setting) {
                    return filteredSettings
                }

                const parts = setting.split(' ')
                if (parts.length !== 2) {
                    return filteredSettings
                }

                const type = parts[0]
                if (type === 'email') {
                    if (filteredSettings && filteredSettings.EMAIL && filteredSettings.EMAIL.settings && filteredSettings.EMAIL.settings.AVAILABILITY_REMINDER) {
                        filteredSettings.EMAIL.settings.AVAILABILITY_REMINDER = {
                            ...filteredSettings.EMAIL.settings.AVAILABILITY_REMINDER,
                            disabled: true,
                        }
                    }
                } else if (type === 'sms') {
                    if (filteredSettings && filteredSettings.SMS && filteredSettings.SMS.settings && filteredSettings.SMS.settings.AVAILABILITY_REMINDER) {
                        filteredSettings.SMS.settings.AVAILABILITY_REMINDER = {
                            ...filteredSettings.SMS.settings.AVAILABILITY_REMINDER,
                            disabled: true,
                        }
                    }
                } else if (type === 'push') {
                    if (filteredSettings && filteredSettings.PUSH && filteredSettings.PUSH.settings && filteredSettings.PUSH.settings.AVAILABILITY_REMINDER) {
                        filteredSettings.PUSH.settings.AVAILABILITY_REMINDER = {
                            ...filteredSettings.PUSH.settings.AVAILABILITY_REMINDER,
                            disabled: true,
                        }
                    }
                }

                return filteredSettings
            },
        },
        created() {
            this.loadNotifications(false, this.preselectedNotification ? this.preselectedNotification._id : null)
            this.buildSettings()
        }
    }
</script>

<style scoped>
    @media only screen and (min-width: 960px) {
        .settings-card-container {
            min-width: 0;
        }
    }
    @media only screen and (max-width: 959px) {
        .settings-card-container {
            min-width: 500px;
        }
    }
</style>
