<template>
    <v-row>
        <v-col class="d-flex justify-center align-center">
            <yellow-pending-icon class="mr-5"></yellow-pending-icon>
            <event-status
                text="cancellation request pending"
                inner-color="rgb(251, 230, 190)"
                outer-color="rgb(242, 182, 66)"></event-status>
        </v-col>
    </v-row>
</template>

<script>
import YellowPendingIcon from "../CustomIcon/YellowPendingIcon";
import EventStatus from "./EventStatus";
export default {
    name: "EventStatusPendingCancellation",
    components: { YellowPendingIcon, EventStatus }
}
</script>

<style scoped>

</style>
