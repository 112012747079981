<template>
    <v-form ref="shiftTimeFormRef" lazy-validation>
        <v-container fluid class="pt-0">
            <v-row>
                <v-col cols="12" style="padding: 0;">
                    <v-divider style="border-color: #000000;"></v-divider>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pl-0">
                    <span class="text--h4" style="color: #000000;">Shift Time</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pl-0 py-0">
                    <v-autocomplete
                        v-model="selectedShiftTimeId"
                        label="Shift Time Preset"
                        :items="shiftTimes"
                        :loading="shiftTimesLoading"
                        :disabled="shiftTimesDisabled"
                        item-value="_id"
                        item-text="label"
                        clearable
                        outlined
                        hide-details
                        @click:clear="onClearShiftTime"
                    >
                        <template v-slot:prepend-inner>
                            <v-icon color="primary">mdi-clock-outline</v-icon>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row class="mt-5">
                <v-col cols="4" class="pl-0 py-0">
                    <start-time-picker
                        v-model="startTime"
                        :max="endTime"
                        @onClearTime="onChangeTime"
                        @onConfirmTime="onChangeTime"
                    ></start-time-picker>
                </v-col>
                <v-col cols="auto" class="d-flex align-center">
                    -
                </v-col>
                <v-col cols="4" class="py-0">
                    <end-time-picker
                        v-model="endTime"
                        :min="startTime"
                        @onClearTime="onChangeTime"
                        @onConfirmTime="onChangeTime"
                    ></end-time-picker>
                </v-col>
                <v-col cols="auto" class="d-flex align-center ml-auto py-0">
                    <v-icon color="primary">
                        mdi-timer-outline
                    </v-icon>
                    <div class="text--secondary ml-1">
                        {{ duration | timeStringFromMinutes }}
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import StartTimePicker from "./StartTimePicker";
import EndTimePicker from "./EndTimePicker";

export default {
    name: "ShiftTimeForm",
    components: {StartTimePicker, EndTimePicker},
    props: {
        value: {
            type: Number,
            default: 0,
        },
        location: {
            type: Object,
            default: () => null
        },
        editMode: {
            type: Boolean,
            default: false,
        },
        initialRequestForm: {
            type: Object,
            default: () => ({})
        },
    },
    data: () => ({
        selectedShiftTimeId: null,
        shiftTimesLoading: false,
        shiftTimes: [],
        shiftTimesMapping: {},
        startTime: null,
        endTime: null,
        matchAgainst: {
            startTime: null,
            endTime: null,
            selectedShiftTimeId: null,
        },
        api: new formHelper()
    }),
    computed: {
        bodyParams() {
            return {
                shift_time_id: this.selectedShiftTimeId,
                duration: this.duration,
                start_time: this.startTime,
            }
        },
        isDirty() {
            const {matchAgainst} = this

            return !_.isEqual(this.startTime, matchAgainst.startTime) ||
                !_.isEqual(this.endTime, matchAgainst.endTime) ||
                !_.isEqual(this.selectedShiftTimeId, matchAgainst.selectedShiftTimeId)
        },
        shiftTimesDisabled() {
            return this.shiftTimesLoading
        },
        duration() {
            const {startTime, endTime} = this
            if (startTime && endTime) {
                const now = moment()
                const from = this.genTimeMoment(now, startTime)
                const to = this.genTimeMoment(now, endTime)
                if (to.isSameOrBefore(from)) {
                    to.add(1, 'days')
                }
                return to.diff(from, 'minutes')
            }
            return 0
        },
        selectedTimezone() {
            return this.$root.globalTimezone
        },
        now() {
            return moment().format('YYYY-MM-DD')
        },
    },
    watch: {
        selectedShiftTimeId: {
            immediate: true,
            handler(shiftTimeId) {
                this.onChangeShiftTime(shiftTimeId)
            }
        },
        location: {
            immediate: true,
            handler(location) {
                if (location && location._id) {
                    this.fetchShiftTimes(location._id)
                }
            }
        },
        duration: {
            immediate: true,
            handler(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        validate() {
            return this.$refs.shiftTimeFormRef.validate()
        },
        postFetch(uri, payload, callbacks = {}) {
            const {
                onStart,
                onSuccess,
                onError,
                onEnd
            } = callbacks

            if (onStart) {
                onStart()
            }
            this.api.post(uri, payload).then(data => {
                if (onSuccess) {
                    onSuccess(data)
                }
            }).catch(data => {
                if (onError) {
                    onError(data)
                }
            }).finally(() => {
                if (onEnd) {
                    onEnd()
                }
            })
        },
        fetchShiftTimes(location_id) {
            this.postFetch('/request-forms/filters/shift_times', {location_id: location_id}, {
                onStart: () => {
                    this.shiftTimesLoading = true
                },
                onSuccess: ({data}) => {
                    this.shiftTimes = data.data
                    this.shiftTimesMapping = this.genMapping(this.shiftTimes)

                    if (this.editMode && this.initialRequestForm && typeof this.initialRequestForm.shift_time_id !== 'undefined' && this.initialRequestForm.shift_time_id) {
                        this.selectedShiftTimeId = this.initialRequestForm.shift_time_id
                        this.matchAgainst = {
                            ...this.matchAgainst,
                            selectedShiftTimeId: this.selectedShiftTimeId,
                        }
                    }

                    if (this.editMode && this.initialRequestForm) {
                        if (this.selectedTimezone) {
                            this.startTime = moment(this.initialRequestForm.start).tz(this.selectedTimezone).format('HH:mm')
                            this.endTime = moment(this.initialRequestForm.end).tz(this.selectedTimezone).format('HH:mm')
                        } else {
                            this.startTime = moment(this.initialRequestForm.start).format('HH:mm')
                            this.endTime = moment(this.initialRequestForm.end).format('HH:mm')
                        }
                        this.matchAgainst = {
                            ...this.matchAgainst,
                            startTime: this.startTime,
                            endTime: this.endTime,
                        }
                    }
                },
                onError: error => console.log(error),
                onEnd: () => {
                    this.shiftTimesLoading = false
                }
            })
        },
        onChangeShiftTime(shiftTimeId) {
            const shiftTime = this.shiftTimesMapping[shiftTimeId]

            if (!shiftTime) {
                return
            }

            const {start_time, duration} = shiftTime

            if (!start_time || !duration) {
                return
            }

            const locationTimezone = this.location && this.location.timezone ? this.location.timezone : null
            const startMoment = this.getTime(start_time, locationTimezone)

            if (!startMoment) {
                console.log("Failed to build time from shift time.")
                return
            }

            this.startTime = this.parseTime(startMoment)
            this.endTime = this.parseTime(startMoment.clone().add(duration, 'minutes'))
        },
        onClearShiftTime() {
            this.startTime = null
            this.endTime = null
        },
        onChangeTime() {
            this.selectedShiftTimeId = null
        },
        genTimeMoment(momentDate, time) {
            const date = momentDate.format('YYYY-MM-DD')
            return moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm')
        },
        getTime(time, locationTimezone) {
            if (time) {
                const timeString = `${this.now} ${time}`
                const format = 'YYYY-MM-DD HH:mm'
                let timeMoment = moment(timeString, format)
                if (locationTimezone) {
                    timeMoment = moment.tz(timeString, format, locationTimezone)
                }
                if (this.selectedTimezone && this.selectedTimezone !== locationTimezone) {
                    return timeMoment.tz(this.selectedTimezone)
                }
                return timeMoment
            }
            return null
        },
        parseTime(timeMoment) {
            if (timeMoment) {
                return timeMoment.format('HH:mm')
            }
            return null
        },
        genMapping(fromArr) {
            if (Array.isArray(fromArr)) {
                return fromArr.reduce((mapping, obj) => ({
                    ...mapping,
                    [obj._id]: {...obj}
                }), {})
            }
            return {}
        },
    },
    created() {
        if (this.editMode && this.initialRequestForm) {
            this.matchAgainst = {
                ...this.matchAgainst,
                startTime: null,
                endTime: null,
                selectedShiftTimeId: null,
            }
        }
    }
}
</script>

<style scoped>

</style>
