<template>
    <v-row>
        <v-col class="d-flex justify-center align-center">
            <green-check-icon class="mr-5"></green-check-icon>
            <event-status
                text="assigned"
                inner-color="rgb(215, 230, 219)"
                outer-color="rgb(90, 172, 94)"></event-status>
        </v-col>
    </v-row>
</template>

<script>
import GreenCheckIcon from "../CustomIcon/GreenCheckIcon";
import EventStatus from "./EventStatus";
export default {
    name: "EventStatusAccepted",
    components: { GreenCheckIcon, EventStatus }
}
</script>

<style scoped>

</style>
