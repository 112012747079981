<template>
    <v-container fluid class="shift-date-form-container">
        <v-row>
            <v-col cols="12" class="pl-0">
                <v-date-picker
                    v-model="selectedDates"
                    :min="minDate"
                    :events="events"
                    :allowed-dates="allowedDates"
                    :readonly="editMode"
                    color="secondary"
                    locale="en-US"
                    elevation="15"
                    full-width
                    multiple
                ></v-date-picker>
                <v-slide-y-transition>
                    <div style="position: relative; top: 15px;">
                        <div class="error--text" v-show="isInvalid" style="position: absolute;">Please select at least one date</div>
                    </div>
                </v-slide-y-transition>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import RecurrencePicker from "./RecurrencePicker";
export default {
    name: "ShiftDateForm",
    components: { RecurrencePicker },
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
        initialRequestForm: {
            type: Object,
            default: () => ({})
        },
        shiftType: {
            type: String,
            default: 'single',
        },
        requestFormIdsByDay: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        prevSelectedDates: [],
        selectedDates: [],
        isInvalid: false,
    }),
    computed: {
        minDate() {
            return moment().format()
        },
        events () {
            if (this.editMode) {
                if (this.initialRequestForm && this.initialRequestForm.request_form_group && Array.isArray(this.requestFormIdsByDay) && this.requestFormIdsByDay.length > 0) {
                    return this.requestFormIdsByDay.map(({ date }) => moment(date).utc().format('YYYY-MM-DD'))
                }
                return null
            }
            return null
        },
        allowedDates () {
            if (this.editMode) {
                if (Array.isArray(this.selectedDates) && this.selectedDates.length > 0) {
                    const dates = this.selectedDates
                    return val => dates.indexOf(val) !== -1
                }
                return null
            }
            return null
        },
        bodyParams () {
            return {
                dates: this.selectedDates
            }
        },
        isDirty () {
            return !_.isEqual(this.prevSelectedDates, this.selectedDates)
        }
    },
    watch: {
        selectedDates(dates) {
            this.isInvalid = dates.length <= 0
        }
    },
    methods: {
        validate() {
            const datesExist = this.selectedDates.length > 0
            this.isInvalid = !datesExist
            return datesExist
        },
    },
    created () {
        if (this.editMode && this.initialRequestForm) {
            if (this.shiftType === 'single') {
                const { start, timezone } = this.initialRequestForm
                if (start) {
                    if (timezone) {
                        this.selectedDates = [moment(start).tz(timezone).format('YYYY-MM-DD')]
                    } else {
                        this.selectedDates = [moment(start).format('YYYY-MM-DD')]
                    }
                    this.prevSelectedDates = Array.from(this.selectedDates)
                }
            } else if (this.shiftType === 'multiple') {
                const dates = this.initialRequestForm?.request_form_group?.selected_dates
                if (Array.isArray(dates)) {
                    if (this.initialRequestForm.hasOwnProperty('requestFormIndex') && this.initialRequestForm.requestFormIndex < dates.length) {
                        this.selectedDates = Array.from(dates).slice(this.initialRequestForm.requestFormIndex)
                    } else {
                        this.selectedDates = Array.from(dates)
                    }
                    this.prevSelectedDates = Array.from(this.selectedDates)
                }
            }
        }
    }
}
</script>

<style scoped>
    .shift-date-form-container {
        padding-top: 0;
        margin-top: 20px;
        margin-bottom: 38px;
    }
</style>
