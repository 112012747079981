<template>
    <v-row>
        <v-col class="d-flex justify-center align-center">
            <red-close-icon class="mr-5"></red-close-icon>
            <event-status
                text="shift cancelled"
                inner-color="rgb(244, 213, 211)"
                outer-color="rgb(197, 31, 22)"></event-status>
        </v-col>
    </v-row>
</template>

<script>
import RedCloseIcon from "../CustomIcon/RedCloseIcon";
import EventStatus from "./EventStatus";
export default {
    name: "EventStatusCancelled",
    components: { RedCloseIcon, EventStatus }
}
</script>

<style scoped>

</style>
